import React from 'react';
import { Link, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Grid, GridItem } from '@chakra-ui/react';

import {
  updateSupplyChainDateMode,
  updateSupplyChainHoveredLocation,
  updateSupplyChainViewMode,
} from '../../../slices/markets/marketReducers';
import {
  selectActiveMarket,
  selectMarketSupplyChainControlState,
  selectSupplyChainHoveredLocation,
} from '../../../slices/markets/selectors/selectBasicMarketData';
import { selectMarketSupplyChainData } from '../../../slices/markets/selectors/selectMarketsSupplyChainData';

import SupplyChainDateModeControl from '../../../components/analyticDashboard/markets/SupplyChainDateModeControl/SupplyChainDateModeControl';
import SupplyChainMap from '../../../components/analyticDashboard/markets/SupplyChainMap/SupplyChainMap';
import SupplyChainViewModeControl from '../../../components/analyticDashboard/markets/SupplyChainViewModeControl/SupplyChainViewModeControl';
import SupplyChainTable from '../../../components/analyticDashboard/markets/SupplyChainTable/SupplyChainTable';
import CardTitle from '../../../shared/CardTitle/CardTitle';
import IUnleashClassFlagProvider from '../../../components/unleash/UnleashClassFlagProvider';
import Constants from '../../../Constants';

// This container specifically serves the supply chain card.

function SupplyChain(/* props */) {
  const dispatch = useDispatch();
  const activeMarket = useSelector(selectActiveMarket);
  const marketSupplyChainData = useSelector(selectMarketSupplyChainData);
  const marketSupplyChainControlState = useSelector(selectMarketSupplyChainControlState);
  const hoveredLocationId = useSelector(selectSupplyChainHoveredLocation);
  const { market: marketParam } = useParams();

  const handleDateModeChange = React.useCallback(v => dispatch(updateSupplyChainDateMode(v)), [dispatch]);
  const handleViewModeChange = React.useCallback(v => dispatch(updateSupplyChainViewMode(v)), [dispatch]);
  const handleHoveredLocationChange = React.useCallback(
    id => dispatch(updateSupplyChainHoveredLocation(id)),
    [dispatch]
  );

  return (
    <Card.Root style={{ height: '100%' }}>
      <Card.Body>
        <Link to={`/analytic-dashboard/market/${marketParam}/supply-chain-details`}>
          <CardTitle includeChevron>Supply Chain</CardTitle>
        </Link>
        <Grid gap={4}>
          <GridItem colSpan={24} style={{ textAlign: 'center' }}>
            <SupplyChainDateModeControl
              controlState={marketSupplyChainControlState}
              onDateModeChange={handleDateModeChange}
            />
          </GridItem>
          <GridItem colSpan={24}>
            <SupplyChainMap
              marketSupplyChainData={marketSupplyChainData}
              onHoveredLocationChange={handleHoveredLocationChange}
              controlState={marketSupplyChainControlState}
              hoveredLocationId={hoveredLocationId}
              activeMarket={activeMarket}
            />
          </GridItem>
          <GridItem colSpan={24}>
            <SupplyChainViewModeControl
              controlState={marketSupplyChainControlState}
              onViewModeChange={handleViewModeChange}
            />
          </GridItem>
          <GridItem colSpan={24}>
            <SupplyChainTable
              marketSupplyChainData={marketSupplyChainData}
              onHoveredLocationChange={handleHoveredLocationChange}
              controlState={marketSupplyChainControlState}
              hoveredLocationId={hoveredLocationId}
              activeMarket={activeMarket}
            />
          </GridItem>
        </Grid>
      </Card.Body>
    </Card.Root>
  );
}

const SupplyChainUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.MARKET_DASHBOARD_SUPPLY_CHAIN}
        show={<SupplyChain {...props} />}
        hide=""
      />
    </>
  );
};

export default SupplyChainUnleashController;
