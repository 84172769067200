import React from 'react';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateQualityTimePeriod,
  updateQualityTimeScale,
  updateQualityPackerPlant,
  updateQualityGrinder,
} from '../../../slices/quality/qualityReducers';
import {
  selectQualityTimeScale,
  selectQualityTimePeriod,
  selectQualityLastUpdated,
  selectQualityPackerPlant,
  selectQualityGrinder,
} from '../../../slices/quality/selectors/selectBasicQualityData';
import { selectQualityPackerPlantOptions } from '../../../slices/quality/selectors/selectQualityPackerPlantOptions';
import { selectQualityGrinderOptions } from '../../../slices/quality/selectors/selectQualityGrinderOptions';
import FilterTimeScale from '../../../components/analyticDashboard/quality/FilterTimeScale/FilterTimeScale';
import FilterTimePeriod from '../../../components/analyticDashboard/quality/FilterTimePeriod/FilterTimePeriod';
import LastUpdated from '../../../components/analyticDashboard/quality/LastUpdated/LastUpdated';
import FilterPackerPlants from '../../../components/analyticDashboard/quality/FilterPackerPlants/FilterPackerPlants';
import FilterGrinders from '../../../components/analyticDashboard/quality/FilterGrinders/FilterGrinders';

export default function QualityFilters() {
  const timeScale = useSelector(selectQualityTimeScale);
  const timePeriod = useSelector(selectQualityTimePeriod);
  const lastUpdated = useSelector(selectQualityLastUpdated);
  const selectedPackerPlant = useSelector(selectQualityPackerPlant);
  const packerPlantOptions = useSelector(selectQualityPackerPlantOptions);
  const selectedGrinder = useSelector(selectQualityGrinder);
  const grinderOptions = useSelector(selectQualityGrinderOptions);

  const dispatch = useDispatch();

  const changeTimeScale = React.useCallback(scale => dispatch(updateQualityTimeScale(scale)), [dispatch]);

  const changeTimePeriod = React.useCallback(
    ([startOfPeriod, endOfPeriod]) => {
      const newStartOfPeriod = new Date(startOfPeriod);
      dispatch(updateQualityTimePeriod([newStartOfPeriod, endOfPeriod]));
    },
    [dispatch]
  );

  const changeActivePackerPlant = React.useCallback(
    packerPlant => {
      dispatch(updateQualityPackerPlant(packerPlant));
    },
    [dispatch]
  );

  const changeActiveGrinder = React.useCallback(
    grinder => {
      dispatch(updateQualityGrinder(grinder));
    },
    [dispatch]
  );

  return (
    <Grid templateColumns="repeat(24, 1fr)" gap={2} style={{ textAlign: 'center' }}>
      <GridItem colSpan={24}>
        <Flex size="lg" alignItems="center" justifyContent="center" gap="24px">
          <FilterTimeScale value={timeScale} onChange={changeTimeScale} />
          <FilterTimePeriod value={timePeriod} onChange={changeTimePeriod} timeScale={timeScale} />
          <LastUpdated value={lastUpdated} />
          <FilterPackerPlants
            value={selectedPackerPlant}
            options={packerPlantOptions}
            onChange={changeActivePackerPlant}
          />
          <FilterGrinders value={selectedGrinder} options={grinderOptions} onChange={changeActiveGrinder} />
        </Flex>
      </GridItem>
    </Grid>
  );
}
