import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, GridItem } from '@chakra-ui/react';

import {
  updateTimeScale,
  // updateActiveProtein, updateActiveCurrency
} from '../../../slices/treasury/treasuryReducers';
import {
  selectTreasuryTimeScale,
  // selectTreasuryActiveProtein,
  // selectTreasuryActiveCurrency,
} from '../../../slices/treasury/selectors/selectTreasuryBasicData';
// import FilterProtein from '../../../components/analyticDashboard/treasury/FilterProtein/FilterProtein';
// import FilterCurrency from '../../../components/analyticDashboard/treasury/FilterCurrency/FilterCurrency';
import FilterTimeScale from '../../../components/analyticDashboard/treasury/FilterTimeScale/FilterTimeScale';

export default function TreasuryFilters(/* props */) {
  const dispatch = useDispatch();

  // const activeProtein = useSelector(selectTreasuryActiveProtein);
  // const activeCurrency = useSelector(selectTreasuryActiveCurrency);
  const timeScale = useSelector(selectTreasuryTimeScale);

  // const handleActiveProteinChange = React.useCallback(
  //   newVal => {
  //     dispatch(updateActiveProtein(newVal));
  //   },
  //   [dispatch]
  // );

  // const handleActiveCurrencyChange = React.useCallback(
  //   newVal => {
  //     dispatch(updateActiveCurrency(newVal));
  //   },
  //   [dispatch]
  // );

  const handleTimeScaleChange = React.useCallback(
    newVal => {
      dispatch(updateTimeScale(newVal));
    },
    [dispatch]
  );

  return (
    <Grid gap="16px" templateColumns="repeat(24, 1fr)">
      <GridItem colSpan={18} />
      <GridItem colSpan={6}>
        <FilterTimeScale timeScale={timeScale} onTimeScaleChange={handleTimeScaleChange} />
      </GridItem>
    </Grid>
  );
}
