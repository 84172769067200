import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Stack, Grid, GridItem } from '@chakra-ui/react';

import LoadingOverlay from '../../../../shared/LoadingOverlay/LoadingOverlay';
import styles from './QualityDetailsClaims.module.css';
import DetailPageHeading from '../../../../shared/CardTitle/CardTitle';
import {
  selectQualityClaimsError,
  selectQualityClaimsLoading,
  selectQualityClaimsSelectedPackerPlantId,
  selectQualityClaimsShouldBeGroupedByPacker,
} from '../../../../slices/quality-details-claims/selectors/selectBasicDetailsClaimsData';
import { selectQualityClaimsFetchParameters } from '../../../../slices/quality-details-claims/selectors/selectQualityClaimsFetchParameters';
import {
  fetchQualityClaimsData,
  setQualityClaimsFilterBy,
  setQualityClaimsFilteredByPackerPlant,
  setQualityClaimsGroupBy,
  setQualityClaimsGroupedByPackerPlant,
  setQualityClaimsSelectedPackerPlantId,
  setQualityClaimsShouldBeGroupedByPacker,
  setQualityClaimsMarketKey,
  setQualityClaimsRegion,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsReducers';
import {
  FILTER_BY_OPTIONS,
  GROUP_BY_OPTIONS,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';
import QualityDetailsClaimsFilters from './QualityDetailsClaimsFilters';
import QualityDetailsClaimsSidebar from './QualityDetailsClaimsSidebar';
import selectAvailableYears from '../../../../slices/quality-details-claims/selectors/selectAvailableYears';
import selectAvailableCategories from '../../../../slices/quality-details-claims/selectors/selectAvailableCategories';
import selectAvailableStatuses from '../../../../slices/quality-details-claims/selectors/selectAvailableStatuses';
import selectAvailablePackerPlants from '../../../../slices/quality/selectors/selectAvailablePackerPlants';
import selectAvailableClaimAmounts from '../../../../slices/quality-details-claims/selectors/selectAvailableClaimAmounts';
import selectAvailableDaysToResolve from '../../../../slices/quality-details-claims/selectors/selectAvailableDaysToResolve';
import QualityDetailsClaimsCharts from './QualityDetailsClaimsCharts';
import { setActiveMarket } from '../../../../slices/markets/marketReducers';
import { setMarketKey } from '../../../../slices/quality/qualityReducers';
import TabBar from '../../../../shared/TabBar';
import { MARKETS } from '../../../../config/markets';
import { Alert } from '@/components/ui/alert';

// This container is for the Quality Details Claims page as a whole and manages data

export default function QualityDetailsClaims() {
  const dispatch = useDispatch();
  const error = useSelector(selectQualityClaimsError);
  const loading = useSelector(selectQualityClaimsLoading);
  const availableYears = useSelector(selectAvailableYears);
  const availableStatuses = useSelector(selectAvailableStatuses);
  const availableCategories = useSelector(selectAvailableCategories);
  const availableClaimAmounts = useSelector(selectAvailableClaimAmounts);
  const availableDaysToResolve = useSelector(selectAvailableDaysToResolve);
  const availablePackerPlants = useSelector(selectAvailablePackerPlants);
  const selectedPackerPlantId = useSelector(selectQualityClaimsSelectedPackerPlantId);
  const shouldBeGroupedByPacker = useSelector(selectQualityClaimsShouldBeGroupedByPacker);
  const { market: marketParam } = useParams();

  const [dataColors, setDataColors] = React.useState({});
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedClaimAmounts, setSelectedClaimAmounts] = useState([]);
  const [selectedDaysToResolve, setSelectedDaysToResolve] = useState([]);
  const [highlightedPackerPlants, setHighlightedPackerPlants] = useState([]);

  // Parameters for data fetch
  const fetchParameters = useSelector(selectQualityClaimsFetchParameters);

  useEffect(() => {
    dispatch(fetchQualityClaimsData(fetchParameters));
  }, [dispatch, fetchParameters]);

  useEffect(() => {
    if (marketParam === MARKETS.Australia.url) {
      dispatch(setActiveMarket(MARKETS.Australia.key));
      dispatch(setMarketKey(MARKETS.Australia.key));
      dispatch(setQualityClaimsMarketKey(MARKETS.Australia.key));
      dispatch(setQualityClaimsRegion(MARKETS.All.key));
    }
    if (marketParam === MARKETS.USA.url) {
      dispatch(setActiveMarket(MARKETS.USA.key));
      dispatch(setMarketKey(MARKETS.USA.key));
      dispatch(setQualityClaimsMarketKey(MARKETS.USA.key));
      dispatch(setQualityClaimsRegion(MARKETS.All.key));
    }
  }, [marketParam, setActiveMarket, setMarketKey, setQualityClaimsMarketKey, setQualityClaimsRegion]);

  useEffect(() => {
    if (selectedPackerPlantId && availablePackerPlants.length) {
      const pac = availablePackerPlants.find(x => x.id === selectedPackerPlantId);
      if (shouldBeGroupedByPacker) {
        dispatch(setQualityClaimsGroupBy(GROUP_BY_OPTIONS.PACKER_PLANT));
        dispatch(setQualityClaimsGroupedByPackerPlant(pac));
        setHighlightedPackerPlants(pac.bottomTenPackerPlants);
      } else {
        dispatch(setQualityClaimsFilterBy(FILTER_BY_OPTIONS.PACKER_PLANT));
        dispatch(setQualityClaimsFilteredByPackerPlant(pac));
      }
      dispatch(setQualityClaimsSelectedPackerPlantId(undefined));
      dispatch(setQualityClaimsShouldBeGroupedByPacker(false));
    }
  }, [selectedPackerPlantId, availablePackerPlants]);

  if (error) {
    return (
      <div>
        <Alert title="Error" status="error">
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <div style={{ position: 'relative' }} className={styles.QualityDetailsClaims_quality}>
      <TabBar />
      <div style={{ paddingLeft: 24 }}>
        <DetailPageHeading>Claims</DetailPageHeading>
      </div>
      <Stack>
        <Grid templateColumns="repeat(24, 1fr)" gap="16px" style={{ padding: `12px 24px 6px` }}>
          <QualityDetailsClaimsFilters />
        </Grid>
        <Grid
          templateColumns="repeat(24, 1fr)"
          gap="0"
          style={{ backgroundColor: '#ffffff', padding: `12px 24px 6px` }}
        >
          <GridItem colSpan={18} style={{ paddingRight: '64px' }}>
            {!loading && (
              <QualityDetailsClaimsCharts
                dataColors={dataColors}
                selectedStatuses={selectedStatuses}
                selectedYears={selectedYears}
                selectedCategories={selectedCategories}
                selectedClaimAmounts={selectedClaimAmounts}
                selectedDaysToResolve={selectedDaysToResolve}
                highlightedPackerPlants={highlightedPackerPlants}
              />
            )}
          </GridItem>
          <GridItem colSpan={6}>
            <QualityDetailsClaimsSidebar
              dataColors={dataColors}
              setDataColors={setDataColors}
              availableYears={availableYears}
              selectedYears={selectedYears}
              setSelectedYears={setSelectedYears}
              availableStatuses={availableStatuses}
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
              availableCategories={availableCategories}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              availableClaimAmounts={availableClaimAmounts}
              selectedClaimAmounts={selectedClaimAmounts}
              setSelectedClaimAmounts={setSelectedClaimAmounts}
              availableDaysToResolve={availableDaysToResolve}
              selectedDaysToResolve={selectedDaysToResolve}
              setSelectedDaysToResolve={setSelectedDaysToResolve}
              availablePackerPlants={availablePackerPlants}
              highlightedPackerPlants={highlightedPackerPlants}
              setHighlightedPackerPlants={setHighlightedPackerPlants}
            />
          </GridItem>
        </Grid>
      </Stack>
      <LoadingOverlay loading={loading} />
    </div>
  );
}
