import { connect } from 'react-redux';
import LiveCLs from '../../components/buyActivity/LiveCLs';
import { getLiveCLs } from '../../actions/actions_buy_activity';
import { fetchFormFields } from '../../actions/actions_form_fields';
import { fetchUnitsOfMeasure } from '../../actions/actions_units_of_measure';

const mapStateToProps = state => {
  return {
    requesting: state.buyActivity.requestingLiveClPrices,
    error: state.buyActivity.liveCLPriceError,
    liveCLs: state.buyActivity.liveCLs,
    wtdAvg76: state.buyActivity.wtdAvg76,
    configs: state.configs,
    token: state.user.token,
    grinders: state.grinders,
    packerPlants: state.packer_plants,
    currencies: state.form_fields.currency,
    unitsOfMeasure: state.units_of_measure,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLiveCLs: config => dispatch(getLiveCLs(config)),
    dispatch,
    getCurrencies: token => dispatch(fetchFormFields(token, '?type=currency')),
    getUnitsOfMeasure: token => dispatch(fetchUnitsOfMeasure(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveCLs);
