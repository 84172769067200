import { get } from 'lodash';
import { connect } from 'react-redux';
import { fetchFormFields } from '../../actions/actions_form_fields';
import Article from '../../components/articles/Article';
import { actions, fetchArticle } from '../../reducers/article';
import withRouter from '@/components/utilities/withRouter';

const mapStateToProps = state => {
  return {
    loading: state.article.loading,
    error: state.article.error,
    article: state.article.article,
    editing: state.article.editing,
    tags: get(state, 'form_fields.tags') || [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTags: token => dispatch(fetchFormFields(token, '?type=tags')),
    getArticle: slug => dispatch(fetchArticle(slug)),
    triggerEditMode: () => dispatch(actions.triggerEditMode()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Article));
