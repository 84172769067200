import { Flex, Box, Text, Button, Link } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import moment from 'moment';
import FileUploader from '../../components/quality/_components/FileUploader';
import opsBulkFileUpload from './OpsBulkUpdaterService';
import { toaster } from '@/components/ui/toaster';
import * as XLSX from 'xlsx';

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pos, setPos] = useState({});
  // const toast = useToast();

  const saveData = async () => {
    setIsLoading(true);
    try {
      if (pos) {
        const response = await opsBulkFileUpload({ data: pos });
        if (response.status === 200) {
          setIsLoading(false);
          // setFieldValue('opsBulkUploader', null);
          toaster.create({
            title: 'Data saved successfully.',
            status: 'success',
          });
        } else {
          setIsLoading(false);
          toaster.create({
            title: 'Failed to save PO data',
            status: 'error',
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      toaster.create({
        title: `Failed to save data ${error} `,
        status: 'error',
      });
    }
    // setFieldValue('opsBulkUploader', null);
    setPos({});
  };

  const { errors, touched, values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      opsBulkUploader: null,
    },
    onSubmit: saveData,
  });

  const convertToJson = (headers, data) => {
    const rows = [];
    const poData = {};
    let poNumber = '';
    let lineItem = '';
    data.forEach(row => {
      const rowData = {};
      const tempclobj = { No: null, temperature: null, cl_result: null };
      const packdate = { packedDate: '', weight: null };
      row.forEach((cell, index) => {
        if (headers[index] === 'PO Number') {
          if (cell != null) {
            poNumber = cell.trim();
            if (!poData[poNumber]) {
              poData[poNumber] = {};
            }
            if (row[1] === null || row[1] === undefined || row[1] === '') {
              lineItem = 'Default';
              poData[poNumber][lineItem] = {
                tempCLObj: [],
                product_type: 'Frozen',
                packdates: [],
                measure_type: 'Bin',
              };
            }
          }
        } else if (headers[index] === 'Line Item (Product)') {
          if (cell != null) {
            lineItem = cell.trim();
            if (!poData[poNumber][lineItem]) {
              poData[poNumber][lineItem] = {
                tempCLObj: [],
                product_type: 'Frozen',
                packdates: [],
                measure_type: 'Bin',
              };
            }
          }
        } else if (headers[index] === 'Measure Type (Bin/Carton)') {
          if (cell != null) {
            const measureType = cell.trim();
            if (measureType !== '') {
              poData[poNumber][lineItem].measure_type = measureType;
            }
          }
        } else if (headers[index] === 'Product Type (Chilled / Frozen)') {
          if (cell != null) {
            const productType = cell.trim();
            if (productType !== '') {
              poData[poNumber][lineItem].product_type = productType;
            }
          }
        } else if (headers[index] === 'Packed Date') {
          if (cell != null && cell !== '') {
            const packedDate = cell.trim();
            if (packedDate !== '') {
              packdate.packedDate = moment(packedDate).format('YYYY-MM-DD');
            }
          }
        } else if (headers[index] === 'Weight') {
          if (cell != null && cell !== '') {
            packdate.weight = cell;
          }
        } else if (headers[index] === 'Bin/Carton/Batch Number') {
          if (cell != null && cell !== '') {
            tempclobj.No = cell;
          }
        } else if (headers[index] === 'Temperature Readings') {
          if (cell != null && cell !== '') {
            tempclobj.temperature = cell;
          }
        } else if (headers[index] === 'Chemical Lean Test Results') {
          if (cell != null && cell !== '') {
            tempclobj.cl_result = cell;
          }
        }
      });
      if (poData[poNumber]?.[lineItem]?.tempCLObj && tempclobj?.No) {
        poData[poNumber][lineItem].tempCLObj?.push({ ...tempclobj });
      }
      if (poData[poNumber]?.[lineItem]?.packdates && packdate?.packedDate) {
        poData[poNumber][lineItem].packdates?.push({ ...packdate });
      }
      rows.push(rowData);
    });
    setPos(poData);
    return rows;
  };

  const downloadFile = async event => {
    try {
      event.preventDefault();
      const response = await fetch('../templates/BulkUploadTemplate.xlsx'); // Adjust the path as needed
      const blob = await response.blob();

      // Create a URL for the blob and set it as href
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'BulkUploadTemplate.xlsx';
      document.body.appendChild(a); // Append to body to initiate download
      a.click();
      document.body.removeChild(a); // Remove the element after download

      // Release the URL after download
      URL.revokeObjectURL(url);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error downloading file:', error);
    }
  };

  const importExcel = file => {
    const reader = new FileReader();
    reader.onload = event => {
      const bstr = event?.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary', cellText: false, cellDates: true });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const fileData = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        blankrows: true,
        defval: null,
        raw: false,
        dateNF: 'yyyy-mm-dd',
      });
      const headers = fileData[0];
      fileData.splice(0, 1);
      convertToJson(headers, fileData);
    };
    reader.readAsBinaryString(file);
  };

  const handleFileUpload = file => {
    importExcel(file);
  };

  useEffect(() => {
    if (errors.opsBulkUploader && touched.opsBulkUploader) {
      toaster.create({
        title: 'Error',
        description: errors.opsBulkUploader,
        status: 'error',
      });
    }
  }, [errors, touched]);

  useEffect(() => {
    if (values.opsBulkUploader) {
      handleFileUpload(values.opsBulkUploader);
    }
  }, [values.opsBulkUploader]);

  return (
    <Formik>
      <form onSubmit={handleSubmit}>
        <Flex gap="20px" css={{ gap: '20px' }} margin="10px 10px" direction="column">
          <Box>
            <Text as="p" fontSize="14px" marginBottom="10px" className="lh-6">
              To download sample bulk upload excel file{' '}
              <Link color="#3A44B0" onClick={downloadFile}>
                Click Here
              </Link>
              .
            </Text>
            <FileUploader
              fieldName="opsBulkUploader"
              setFieldValue={setFieldValue}
              fileObject={values.opsBulkUploader}
              accept=".xls,.xlsx"
              label="Select Excel file."
            />
          </Box>

          <Box textAlign="right">
            <Button
              type="submit"
              width="126px"
              height="43px"
              colorScheme="actionPrimary"
              loadingText="Saving..."
              loading={isLoading}
              padding-top="10px"
            >
              <Text as="p" fontSize="16px" fontWeight="black">
                Save
              </Text>
            </Button>
          </Box>
        </Flex>
      </form>
    </Formik>
  );
};
