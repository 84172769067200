import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import DatetimeRangePicker from 'react-datetime-range-picker';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { HStack } from '@chakra-ui/react';

import FilterTitle from '../../../components/analyticDashboard/marketsDetails/FilterTitle';
import styles from './Volume.module.css';

import {
  MARKET_FOCUS,
  VOLUME,
  GROUP_BY,
  FREQUENCY,
  TIME_MODE,
  TIME_RANGE,
} from '../../../slices/markets-details/marketsDetailsConfig';
import {
  selectMarketsVolumeDetailTimeMode,
  selectMarketsVolumeDetailFilters,
  selectMarketsVolumeDetailTimeRange,
  selectMarketsVolumeDetailTimeRangeSelection,
} from '../../../slices/markets-details/selectors/selectMarketsVolumeDetailData';
import {
  updateMarket,
  updateVolume,
  updateGroupBy,
  updateTimeMode,
  updateTime,
  updateFrequency,
} from '../../../slices/markets-details/volumeDetails';
import { RadioGroup, Radio } from '@/components/ui/radio';

const constantsToOptions = (constants, { includeBlank = true, blankLabel = '--' } = {}) => {
  const options = Object.entries(constants);
  if (includeBlank) {
    options.unshift([null, blankLabel]);
  }
  return options;
};

const makeMenuItem = ([key, label]) => <Menu.Item key={label}>{label}</Menu.Item>;

const makeRadioButton = ([key, label]) => (
  <Radio key={key} value={label}>
    {label}
  </Radio>
);

const Wrapper = ({ columns, text, children }) => (
  <div data-colums={columns} className={styles.filterItem}>
    <FilterTitle>{text}</FilterTitle>
    <div className={styles.controls}>{children}</div>
  </div>
);

export default function MarketsDetailVolumeFilters() {
  const dispatch = useDispatch();
  const activeFilters = useSelector(selectMarketsVolumeDetailFilters);
  const timeRangeSelection = useSelector(selectMarketsVolumeDetailTimeRangeSelection);
  const timeRange = useSelector(selectMarketsVolumeDetailTimeRange);
  const timeMode = useSelector(selectMarketsVolumeDetailTimeMode);

  const changeMarket = React.useCallback(({ key }) => dispatch(updateMarket(key)), [dispatch]);

  const removeBlank = value => (value !== '--' ? value : null);
  const changeVolume = React.useCallback(({ key }) => dispatch(updateVolume(removeBlank(key))), [dispatch]);

  const changeGroupBy = React.useCallback(({ key }) => dispatch(updateGroupBy(removeBlank(key))), [dispatch]);

  const changeTimeMode = React.useCallback(({ key }) => dispatch(updateTimeMode(key)), [dispatch]);

  const changeTimeRangeSelection = React.useCallback(
    event => dispatch(updateTime({ timeRangeSelection: event.value })),
    [dispatch]
  );

  const changeCustomTimeRange = React.useCallback(period =>
    dispatch(
      updateTime({
        timeRangeSelection: TIME_RANGE.CUSTOM,
        timeRange: [period[0].toDate(), period[1].toDate()],
      })
    )
  );

  const changeFrequency = React.useCallback(event => dispatch(updateFrequency(event.value)), [dispatch]);

  const marketMenu = (
    <Menu onClick={changeMarket}>
      {constantsToOptions(MARKET_FOCUS, {
        includeBlank: false,
      }).map(makeMenuItem)}
    </Menu>
  );

  const volumeMenu = (
    <Menu onClick={changeVolume}>
      {constantsToOptions(VOLUME, {
        includeBlank: false,
      }).map(makeMenuItem)}
    </Menu>
  );

  const groupByMenu = <Menu onClick={changeGroupBy}>{constantsToOptions(GROUP_BY).map(makeMenuItem)}</Menu>;

  const timeModeMenu = (
    <Menu onClick={changeTimeMode}>{constantsToOptions(TIME_MODE, { includeBlank: false }).map(makeMenuItem)}</Menu>
  );

  const timeRangeButtons = constantsToOptions(TIME_RANGE, {
    includeBlank: false,
  })
    .filter(([key]) => key !== 'CUSTOM')
    .map(makeRadioButton);

  const frequencyButtons = constantsToOptions(FREQUENCY, {
    includeBlank: false,
  }).map(makeRadioButton);

  return (
    <div className={styles.filterRow}>
      <Wrapper columns={3} text="Market">
        <Dropdown overlay={marketMenu} trigger="click">
          <span className={styles.dropdown}>
            {activeFilters.market} <MdOutlineKeyboardArrowDown />
          </span>
        </Dropdown>
      </Wrapper>
      <Wrapper columns={3} text="Volume">
        <Dropdown overlay={volumeMenu} trigger="click">
          <span className={styles.dropdown}>
            {activeFilters.volume} <MdOutlineKeyboardArrowDown />
          </span>
        </Dropdown>
      </Wrapper>
      <Wrapper columns={3} text="Group By">
        <Dropdown overlay={groupByMenu} trigger="click">
          <span className={styles.dropdown}>
            {activeFilters.groupBy || '--'} <MdOutlineKeyboardArrowDown />
          </span>
        </Dropdown>
      </Wrapper>
      <Wrapper columns={11} text="Time Range">
        <Dropdown overlay={timeModeMenu} trigger="click">
          <span className={`${styles.dropdown} ${styles.dropdownTimeMode}`}>
            {timeMode} <MdOutlineKeyboardArrowDown />
          </span>
        </Dropdown>
        {timeMode === TIME_MODE.HISTORICAL && (
          <>
            <RadioGroup value={timeRangeSelection} onChange={changeTimeRangeSelection}>
              <HStack alignItems="center" justifyContent="end">
                {timeRangeButtons}
              </HStack>
            </RadioGroup>
            {/* <DatePicker.RangePicker
              picker="date"
              allowClear={false}
              value={timeRange.map(d => (d && moment(d)) || moment(0))}
              format={m => {
                return m.format('D MMM YYYY');
              }}
              onChange={newPeriod => {
                if (!newPeriod) return;
                changeCustomTimeRange(newPeriod);
              }}
            /> */}
            <DatetimeRangePicker
              className="date-range-picker"
              inputProps={{
                placeholder: 'Date range',
              }}
              closeOnSelect
              startDate={moment(timeRange[0]).toDate()}
              endDate={moment(timeRange[1]).toDate()}
              onChange={e => {
                changeCustomTimeRange([moment(e.startDate).toDate(), moment(e.endDate).toDate()]);
              }}
              timeFormat={false}
              dateFormat="DD/MM/YYYY"
            />
          </>
        )}
      </Wrapper>
      {timeMode === TIME_MODE.HISTORICAL && (
        <Wrapper columns={4} text="Frequency">
          <RadioGroup className={styles.radioGroup} value={activeFilters.frequency} onChange={changeFrequency}>
            <HStack alignItems="center" justifyContent="end">
              {frequencyButtons}
            </HStack>
          </RadioGroup>
        </Wrapper>
      )}
    </div>
  );
}
