import React from 'react';
import { Link, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { getQuarter, getYear, format } from 'date-fns';
import { Grid, GridItem } from '@chakra-ui/react';

import useAnnotations from '../../../modules/hooks/useAnnotation';
import {
  selectTreasuryActiveMarket,
  selectTreasuryTimeScale,
} from '../../../slices/treasury/selectors/selectTreasuryBasicData';
import { TREASURY_TIME_INTERVAL } from '../../../slices/treasury/treasuryConfig';
import {
  updateActiveVolume,
  updateActivePrice,
  updateActiveCost,
  TREASURY_DETAILS_VOLUME,
} from '../../../slices/treasury-details/treasuryDetailsReducers';
import { selectTreasuryData } from '../../../slices/treasury/selectors/selectTreasuryData';
import VolumeChart from '../../../components/analyticDashboard/treasury/VolumeChart/VolumeChart';
import AnnotationModal from '../../../shared/AnnotationModal/AnnotationModal';
import AnnotationList from '../../../shared/AnnotationList/AnnotationList';
import { ChevronRight } from '../../../shared/Icons';
import { Text } from '@chakra-ui/react';

// This container specifically serves the risk management chart
export default function RiskManagement(/* props */) {
  const dispatch = useDispatch();
  const data = useSelector(selectTreasuryData);
  const timeScale = useSelector(selectTreasuryTimeScale);
  const annotationState = useAnnotations({
    page: 'treasury',
    focusSelector: selectTreasuryActiveMarket,
    module: 'risk-management',
    timeScaleSelector: selectTreasuryTimeScale,
  });
  const { addNewAnnotation, editAnnotation, annotations } = annotationState;
  const time = useSelector(selectTreasuryTimeScale);
  const { market: marketParam } = useParams();

  const handleRiskManagementSelect = React.useCallback(() => {
    dispatch(updateActiveVolume(TREASURY_DETAILS_VOLUME.ALL_STRATEGIES));
    dispatch(updateActivePrice(null));
    dispatch(updateActiveCost(null));
  }, [dispatch]);

  if (!data) return null;

  const currentInterval =
    timeScale === TREASURY_TIME_INTERVAL.QUARTERLY
      ? `Q${getQuarter(new Date())} ${getYear(new Date())}`
      : `${format(new Date(), 'MMM')} ${getYear(new Date())}`;

  const annotationIntervalformat = timeScale === TREASURY_TIME_INTERVAL.QUARTERLY ? 'QQQ yyyy' : 'MMM yyyy';

  const ind = data.findIndex(x => {
    const isMatch = x.interval === currentInterval;
    return isMatch;
  });

  return (
    <>
      <Grid>
        <GridItem>
          <Link
            to={`/analytic-dashboard/treasury/${marketParam}/treasury-details`}
            onClick={() => handleRiskManagementSelect()}
          >
            <Text style={{ textTransform: 'uppercase', color: '#fc5d36' }}>
              {time} coverage and outlook
              <div style={{ display: 'inline-block', width: '12px' }}>
                <ChevronRight />
              </div>
            </Text>
          </Link>
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(24, 1fr)">
        <GridItem colSpan={20}>
          <VolumeChart
            riskManagementData={data}
            splitIndex={ind === -1 ? -1 : ind + 1}
            addNewAnnotation={addNewAnnotation}
            editAnnotation={editAnnotation}
            moduleAnnotations={annotations}
            annotationIntervalFormat={annotationIntervalformat}
            volumeType="percent"
            withDataTable
          />
          <AnnotationModal {...annotationState} />
        </GridItem>
        <GridItem colSpan={4}>
          <AnnotationList annotationIntervalFormat={annotationIntervalformat} {...annotationState} />
        </GridItem>
      </Grid>
    </>
  );
}
