import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Spacer, Flex, Heading } from '@chakra-ui/react';
import SelectField from '../../../../../components/basic/SelectField';
import Button from '../../../../../components/core/Button';
import { useSelector } from 'react-redux';
import { selectOrderOptions } from '../../../../../slices/masterData/selectOrderEntities';
import Constants from '../../../../../Constants';
import { DialogBody, DialogContent, DialogHeader, DialogRoot, DialogCloseTrigger } from '@/components/ui/dialog';

const propTypes = {
  // Modal Props:
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onSubmit: PropTypes.func,
  view: PropTypes.shape({
    columns: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  }),
  pos: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  posSelectedIds: PropTypes.instanceOf(Object),
};

const BulkUpdaterModal = props => {
  const { isOpen, onCloseModal, onSubmit, view, pos, posSelectedIds } = props;

  const [columns, setColumns] = useState();
  const [inputProducts, setInputProducts] = useState();
  const [row, setRow] = useState({});
  const [selectedColumn, setSelectedColumn] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const { transportOptions } = useSelector(selectOrderOptions);

  useEffect(() => {
    setColumns(
      view?.columns
        .reduce((cols, col) => {
          if (Array.isArray(col.columns)) {
            return cols.concat(col.columns.map(c => ({ ...c, name: `${col.name} - ${c.name}`, rowKey: col.rowKey })));
          }
          cols.push(col);
          return cols;
        }, [])
        .filter(c => !!c.editor)
        .map(c => ({ label: c.name, value: c.key, column: c }))
    );
  }, [view]);

  const onSetInputProducts = () => {
    const inputProds = [];
    pos?.forEach(po => {
      inputProds.push(...po.lines?.map(line => ({ label: line.input_product?.name, value: line.input_product?.uid })));
    });
    const uniqueInputProds = [...new Map(inputProds.map(item => [item.value, item])).values()];
    setInputProducts(uniqueInputProds);
  };
  useEffect(() => {
    onSetInputProducts();
  }, []);

  const handleSubmit = () => {
    const newPos = JSON.parse(JSON.stringify(pos));
    onCloseModal();
    const spread = (item, row_) => {
      const newItem = { ...item };
      Object.keys(row_).forEach(key => {
        if (typeof row_[key] === 'object' && Array.isArray(newItem[key])) {
          if (selectedProduct && selectedColumn?.key === 'price_per_unit') {
            newItem[key] = newItem[key].map(l => {
              //Calculate Buy Price per unit based on the selected product
              if (l.input_product?.uid === selectedProduct.value) {
                const transportCost = item.transport_cost_per_unit;
                const pricePerUnit = row_[key]?.price_per_unit;
                if (item.product_type === Constants.PRODUCT_TYPE_LIST.PORK) {
                  row_[key].buy_price_per_unit = pricePerUnit + l.group_housed_premium_rate + transportCost;
                } else if (item.product_type === Constants.PRODUCT_TYPE_LIST.BEEF) {
                  row_[key].buy_price_per_unit = pricePerUnit + transportCost;
                }
              }
              return l.input_product?.uid === selectedProduct.value
                ? spread(l, row_[key])
                : spread(l, { [selectedColumn.key]: l[selectedColumn.key] });
            });
          } else {
            newItem[key] = newItem[key].map(l => spread(l, row_[key]));
          }
        } else if (selectedColumn?.key === 'packer_plant') {
          const transportCost = transportOptions.find(transportOption => {
            return (
              transportOption.grinder_uid === item?.grinder_uid &&
              transportOption.packer_plant_id === row.packer_plant_id
            );
          });
          newItem.transport_cost_per_unit = transportCost ? transportCost.cost.toFixed(4) : 0;
          newItem[key] = row_[key];
        } else {
          newItem[key] = row_[key];
        }
      });
      return newItem;
    };

    onSubmit(
      newPos.map(po => {
        if (selectedProduct && selectedColumn?.key === 'price_per_unit') {
          if (po.lines.some(line => line.input_product?.uid == selectedProduct.value)) {
            return spread(po, row);
          }
        }
        if (selectedColumn?.key !== 'price_per_unit' && posSelectedIds.has(po.id)) {
          return spread(po, row);
        }
        return po;
      })
    );
  };

  return (
    <DialogRoot size="lg" open={isOpen} onClose={onCloseModal}>
      <DialogContent minHeight="200px">
        <DialogHeader>
        <Heading size="md">Bulk Edit</Heading></DialogHeader>
        <DialogCloseTrigger onClick={onCloseModal} />
        <DialogBody>
          <Flex mb="20px">
            <Box w="220px">
              <SelectField
                label={<strong>Field</strong>}
                {...{
                  name: 'columns',
                  options: columns,
                  onChange: option => setSelectedColumn(option.column),
                  closeMenuOnSelect: true,
                  value: selectedColumn ? { label: selectedColumn.name, value: selectedColumn.key } : undefined,
                }}
              />
            </Box>
            <Spacer />
            {selectedColumn?.key === 'price_per_unit' && (
              <Box w="220px" mr="20px" ml="20px">
                <SelectField
                  label={<strong>Product</strong>}
                  {...{
                    name: 'products',
                    options: inputProducts,
                    onChange: option => setSelectedProduct(option),
                    closeMenuOnSelect: true,
                  }}
                />
              </Box>
            )}
            <Spacer />
            <Box w="220px">
              {((selectedColumn && selectedColumn?.key === 'price_per_unit' && selectedProduct) ||
                (selectedColumn && selectedColumn?.key !== 'price_per_unit')) && (
                <>
                  <strong>New Value</strong>
                  <selectedColumn.editor
                    {...{
                      row: selectedColumn.rowKey ? row[selectedColumn.rowKey] || {} : row,
                      column: selectedColumn,
                      disableGridMode: true,
                      onRowChange: row_ => {
                        const rowChangeValue = { ...row_ };
                        if (selectedColumn.key === 'packer_plant') {
                          if (rowChangeValue.transport_cost_per_unit === 0) {
                            delete rowChangeValue.transport_cost_per_unit;
                          }
                        }
                        if (selectedColumn.rowKey) {
                          setRow({ ...row, [selectedColumn.rowKey]: rowChangeValue });
                          return;
                        }
                        setRow(rowChangeValue);
                      },
                      onClose: () => {},
                    }}
                  />
                </>
              )}
            </Box>
          </Flex>
          <Flex justifyContent="flex-end" mt="40px">
            <Button ml="auto" width="110px" onClick={handleSubmit} disabled={Object.keys(row).length === 0}>
              Submit
            </Button>
          </Flex>
        </DialogBody>
      </DialogContent>
    </DialogRoot>
  );
};

BulkUpdaterModal.propTypes = propTypes;

export default BulkUpdaterModal;
