import React, { useEffect, useState } from 'react';
import { GridItem, Stack, Grid } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import DetailPageHeading from '../../../../shared/CardTitle/CardTitle';
import LoadingOverlay from '../../../../shared/LoadingOverlay/LoadingOverlay';
import TabBar from '../../../../shared/TabBar';
import {
  INCIDENTS_OPTIONS,
  TIME_RANGE_OPTIONS,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsConfig';
import {
  fetchQualityNCRsData,
  setQualityNCRsActivePackerPlants,
  setQualityNCRsSelectedPackerPlantId,
  setQualityNCRsMarketKey,
  setQualityNCRsRegion,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsReducers';
import {
  selectAvailableCategories,
  selectAvailableCategoriesWithSubcategories,
} from '../../../../slices/quality-details-ncrs/selectors/selectAvailableCategories';
import selectAvailablePackerPlants from '../../../../slices/quality/selectors/selectAvailablePackerPlants';
import selectAvailableYears from '../../../../slices/quality-details-ncrs/selectors/selectAvailableYears';
import {
  selectQualityNCRsError,
  selectQualityNCRsIncidents,
  selectQualityNCRsLoading,
  selectQualityNCRsSelectedPackerPlantId,
  selectQualityNCRsTimeRange,
} from '../../../../slices/quality-details-ncrs/selectors/selectBasicDetailsNcrsData';
import { selectQualityNCRsFetchParameters } from '../../../../slices/quality-details-ncrs/selectors/selectQualityNCRsFetchParameters';
import { setMarketKey } from '../../../../slices/quality/qualityReducers';
import { setActiveMarket } from '../../../../slices/markets/marketReducers';
import QualityDetailsNCRsCharts from './QualityDetailsNCRsCharts';
import QualityDetailsNCRsFilters from './QualityDetailsNCRsFilters';
import QualityDetailsNCRsSidebar from './QualityDetailsNCRsSidebar';
import { MARKETS } from '../../../../config/markets';
import styles from './QualityDetailsNCRs.module.css';
import { Alert } from '@/components/ui/alert';

// This container is for the Quality Details NCRs page as a whole and manages data

export default function QualityDetailsNCRs() {
  const dispatch = useDispatch();
  const error = useSelector(selectQualityNCRsError);
  const loading = useSelector(selectQualityNCRsLoading);
  const availableYears = useSelector(selectAvailableYears);
  const availableCategories = useSelector(selectAvailableCategories);
  const availableCategoriesWithSubcategories = useSelector(selectAvailableCategoriesWithSubcategories);
  const availableMarkets = ['Domestic', 'Export'];
  const availablePackerPlants = useSelector(selectAvailablePackerPlants);
  const selectedPackerPlantId = useSelector(selectQualityNCRsSelectedPackerPlantId);
  const incidentsMode = useSelector(selectQualityNCRsIncidents);
  const timeRange = useSelector(selectQualityNCRsTimeRange);
  const { market: marketParam } = useParams();

  const [dataColors, setDataColors] = useState({});

  useEffect(() => {
    if (selectedPackerPlantId && availablePackerPlants.length > 0) {
      const pac = availablePackerPlants.find(x => x.id === selectedPackerPlantId);
      if (
        ((incidentsMode === INCIDENTS_OPTIONS.TOTAL || !incidentsMode) &&
          timeRange === TIME_RANGE_OPTIONS.HISTORICAL) ||
        incidentsMode === INCIDENTS_OPTIONS.BY_CATEGORY ||
        incidentsMode === INCIDENTS_OPTIONS.BY_SUBCATEGORY
      ) {
        dispatch(setQualityNCRsActivePackerPlants([pac, undefined]));
      } else {
        dispatch(setQualityNCRsActivePackerPlants([pac]));
      }
      dispatch(setQualityNCRsSelectedPackerPlantId(undefined));
    }
  }, [selectedPackerPlantId, availablePackerPlants]);

  useEffect(() => {
    if (marketParam === MARKETS.Australia.url) {
      dispatch(setActiveMarket(MARKETS.Australia.key));
      dispatch(setMarketKey(MARKETS.Australia.key));
      dispatch(setQualityNCRsMarketKey(MARKETS.Australia.key));
      dispatch(setQualityNCRsRegion(MARKETS.All.key));
    }
    if (marketParam === MARKETS.USA.url) {
      dispatch(setActiveMarket(MARKETS.USA.key));
      dispatch(setMarketKey(MARKETS.USA.key));
      dispatch(setQualityNCRsMarketKey(MARKETS.USA.key));
      dispatch(setQualityNCRsRegion(MARKETS.All.key));
    }
  }, [marketParam, setActiveMarket, setMarketKey, setQualityNCRsMarketKey]);

  // Parameters for data fetch
  const fetchParameters = useSelector(selectQualityNCRsFetchParameters);

  useEffect(() => {
    dispatch(fetchQualityNCRsData(fetchParameters));
  }, [dispatch, fetchParameters]);

  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [packerMode, setPackerMode] = useState(undefined);
  const [breakdownPackerPlant, setBreakdownPackerPlant] = useState(undefined);
  const [highlightedPackerPlants, setHighlightedPackerPlants] = useState([]);

  if (error) {
    return (
      <div>
        <Alert title="Error" status="error">
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <div
      style={{ position: 'relative' }}
      className={styles.QualityDetailsNCRs_quality}
      data-test="QualityDetailsNCRs-component"
    >
      <TabBar setActiveMarketAction={setMarketKey} />
      <div style={{ paddingLeft: 24 }}>
        <DetailPageHeading>NCRs</DetailPageHeading>
      </div>
      <Stack>
        <Grid templateColumns="repeat(24, 1fr)" gap="16px" style={{ padding: `12px 24px 6px` }}>
          <QualityDetailsNCRsFilters />
        </Grid>
        <Grid
          templateColumns="repeat(24, 1fr)"
          gap="0"
          style={{ backgroundColor: '#ffffff', padding: `12px 24px 6px` }}
        >
          <GridItem colSpan={18} style={{ paddingRight: '64px' }}>
            {!loading && (
              <QualityDetailsNCRsCharts
                dataColors={dataColors}
                selectedYears={selectedYears}
                selectedCategories={selectedCategories}
                selectedSubcategories={selectedSubcategories}
                selectedMarkets={selectedMarkets}
                packerMode={packerMode}
                breakdownPackerPlant={breakdownPackerPlant}
                highlightedPackerPlants={highlightedPackerPlants}
              />
            )}
          </GridItem>
          <GridItem colSpan={6}>
            <QualityDetailsNCRsSidebar
              dataColors={dataColors}
              setDataColors={setDataColors}
              availableYears={availableYears}
              selectedYears={selectedYears}
              setSelectedYears={setSelectedYears}
              availableCategories={availableCategories}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              availableCategoriesWithSubcategories={availableCategoriesWithSubcategories}
              selectedSubcategories={selectedSubcategories}
              setSelectedSubcategories={setSelectedSubcategories}
              availableMarkets={availableMarkets}
              selectedMarkets={selectedMarkets}
              setSelectedMarkets={setSelectedMarkets}
              availablePackerPlants={availablePackerPlants}
              packerMode={packerMode}
              setPackerMode={setPackerMode}
              breakdownPackerPlant={breakdownPackerPlant}
              setBreakdownPackerPlant={setBreakdownPackerPlant}
              highlightedPackerPlants={highlightedPackerPlants}
              setHighlightedPackerPlants={setHighlightedPackerPlants}
            />
          </GridItem>
        </Grid>
      </Stack>
      <LoadingOverlay loading={loading} />
    </div>
  );
}
