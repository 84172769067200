import React from 'react';
import { useSelector } from 'react-redux';
import { getYear, getQuarter, format } from 'date-fns';
import { Grid, GridItem } from '@chakra-ui/react';

import useAnnotations from '../../../modules/hooks/useAnnotation';
import { TREASURY_TIME_INTERVAL } from '../../../slices/treasury/treasuryConfig';
import {
  selectTreasuryActiveMarket,
  selectTreasuryTimeScale,
} from '../../../slices/treasury/selectors/selectTreasuryBasicData';
// import {
//   updateActiveVolume,
//   updateActivePrice,
//   updateActiveCost,
//   TREASURY_DETAILS_COST,
// } from '../../../slices/treasury-details/treasuryDetailsReducers';
import { selectTreasuryData } from '../../../slices/treasury/selectors/selectTreasuryData';
import { selectTreasuryExpectedVolumeData } from '../../../slices/treasury/selectors/selectTreasuryExpectedVolumeData';
import CostChart from '../../../components/analyticDashboard/treasury/CostChart/CostChart';
import ExpectedVolumeChart from '../../../components/analyticDashboard/treasury/ExpectedVolumeChart/ExpectedVolumeChart';
import AnnotationModal from '../../../shared/AnnotationModal/AnnotationModal';
import AnnotationList from '../../../shared/AnnotationList/AnnotationList';

export default function CostSavingsAndExpectedVolume(/* props */) {
  // const dispatch = useDispatch();
  const data = useSelector(selectTreasuryData);
  const expectedVolumeData = useSelector(selectTreasuryExpectedVolumeData);
  const timeScale = useSelector(selectTreasuryTimeScale);
  const activeMarket = useSelector(selectTreasuryActiveMarket);

  const annotationStateCostSavings = useAnnotations({
    page: 'treasury',
    focusSelector: selectTreasuryActiveMarket,
    module: 'cost-savings',
    timeScaleSelector: selectTreasuryTimeScale,
  });
  const annotationStateExpectedVolume = useAnnotations({
    page: 'treasury',
    focusSelector: selectTreasuryActiveMarket,
    module: 'expected-volume',
    timeScaleSelector: selectTreasuryTimeScale,
  });

  // const handleCostSelect = React.useCallback(() => {
  //   dispatch(updateActiveVolume(null));
  //   dispatch(updateActivePrice(null));
  //   dispatch(updateActiveCost(TREASURY_DETAILS_COST.SAVINGS));
  // }, [dispatch]);

  if (!data || !expectedVolumeData) return null;

  const currentInterval =
    timeScale === TREASURY_TIME_INTERVAL.QUARTERLY
      ? `Q${getQuarter(new Date())} ${getYear(new Date())}`
      : `${format(new Date(), 'MMM')} ${getYear(new Date())}`;

  const annotationIntervalFormat = timeScale === TREASURY_TIME_INTERVAL.QUARTERLY ? 'QQQ yyyy' : 'MMM yyyy';

  const currentYear = getYear(new Date());
  let ind = data.findIndex(x => x.interval === currentInterval);
  if (ind === -1) {
    // If we can't find exact matching data for current quarter, just use the first record of the year.
    ind = data.findIndex(x => x.interval.includes(currentYear));
  }
  // const costSavingsSize = ind + 1;
  // const expectedVolumeSize = data.length - costSavingsSize;
  return (
    <Grid templateColumns="repeat(12, 1fr)">
      <GridItem colSpan={10} style={{ display: 'flex' }}>
        <div
          className="cost-savings__container"
          style={{
            width: 'calc(64% + 355px)',
          }}
        >
          {/* <Link to={`/analytic-dashboard/treasury/${marketParam}/treasury-details`} onClick={() => handleCostSelect()}> */}
          <div style={{ textTransform: 'uppercase', color: '#fc5d36' }}>
            cost savings
            <div style={{ display: 'inline-block', width: '12px' }}>{/* <ChevronRight /> */}</div>
          </div>
          {/* </Link> */}
          <CostChart
            costSavingsData={data}
            splitIndex={ind === -1 ? -1 : ind + 1}
            addNewAnnotation={annotationStateCostSavings.addNewAnnotation}
            editAnnotation={annotationStateCostSavings.editAnnotation}
            moduleAnnotations={annotationStateCostSavings.annotations}
            annotationIntervalFormat={annotationIntervalFormat}
            hasShowBreakdownOption
          />
        </div>
        <div
          className="expected-volume__container"
          style={{
            width: 'calc(36% - 35px)',
          }}
        >
          <div style={{ textTransform: 'uppercase', color: '#fc5d36' }}>expected volume</div>
          <ExpectedVolumeChart
            expectedVolumeData={expectedVolumeData}
            timeScale={timeScale}
            addNewAnnotation={annotationStateExpectedVolume.addNewAnnotation}
            editAnnotation={annotationStateExpectedVolume.editAnnotation}
            moduleAnnotations={annotationStateExpectedVolume.annotations}
            annotationIntervalFormat={annotationIntervalFormat}
            activeMarket={activeMarket}
          />
        </div>
        <AnnotationModal {...annotationStateCostSavings} />
        <AnnotationModal {...annotationStateExpectedVolume} />
      </GridItem>
      <GridItem colSpan={2}>
        <span style={{ textTransform: 'uppercase' }}>Cost savings</span>
        <AnnotationList annotationIntervalFormat={annotationIntervalFormat} {...annotationStateCostSavings} />
        <span style={{ textTransform: 'uppercase' }}>Expected volume</span>
        <AnnotationList annotationIntervalFormat={annotationIntervalFormat} {...annotationStateExpectedVolume} />
      </GridItem>
    </Grid>
  );
}
