import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderHistory } from '../../../../../actions/actions_orders';
import { fetchUsers } from '../../../../../actions/actions_users';
import Loading from '../../../../../components/basic/Loading';
import BoabForm from '../../../../../components/core/formik/Form';
import { selectCurrentUser } from '../../../../../reducers/reducer_user';
import { selectBuyerOptions, selectOrderOptions } from '../../../../../slices/masterData/selectOrderEntities';
// Components
import { formConfig, initialize, validationSchema } from './formConfig';

const propTypes = {
  currentPage: PropTypes.number,
  limit: PropTypes.number,
  sortColumns: PropTypes.arrayOf(
    PropTypes.shape({
      columnKey: PropTypes.string.isRequired,
      direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
    })
  ),
  onSubmitSeach: PropTypes.func,
};

const DEFAULT_PAGE = 1;
// const DEFAULT_PAGE_LIMIT = 50;

export function formatArray(item) {
  const list = Array.isArray(item) ? item : [item];
  return list.map(itm => itm?.value);
}

// TODO -- Formatting of form payload for sending to the BE is still a WIP.
export function submitSearchForm(formValues, props) {
  const { dispatch, token, currentPage, limit, sortColumns } = props;
  const {
    grinderPoNumber,
    internalPoNumber,
    vendorInvoiceNumber,
    vendorSoNumber,
    buyer,
    deliveryDateStart,
    deliveryDateEnd,
    createdDateStart,
    createdDateEnd,
    grinders,
    purchasingOffice,
    packers,
    packerPlants,
    inputProducts,
    productTypes,
    orderType,
    tags,
    bolNumber,
    containerNumber,
    bookingReferenceNumber,
    portMark,
    etdDateStart,
    etdDateEnd,
    etaDateStart,
    etaDateEnd,
    voyageNumber,
    vesselName,
    orderShipmentTypes,
    coldstore,
    missingDocType,
    loadPort,
    destPort,
    packerPlantNumber,
    grinderPoNumberRange,
    isColdstoreRequired,
  } = formValues;

  const searchFilters = {
    bol_number: bolNumber,
    booking_reference_number: bookingReferenceNumber,
    container_number: containerNumber,
    isColdStoreRequired: isColdstoreRequired?.value,
    delivery_end_date: deliveryDateEnd,
    delivery_start_date: deliveryDateStart,
    etd_end_date: etdDateEnd,
    etd_start_date: etdDateStart,
    eta_end_date: etaDateEnd,
    eta_start_date: etaDateStart,
    vessel_name: vesselName,
    voyage_number: voyageNumber,
    internal_po_number: internalPoNumber,
    grinder_po_number: grinderPoNumber,
    grinder_po_number_range: grinderPoNumberRange,
    grinder_uids: formatArray(grinders),
    input_product_uids: formatArray(inputProducts),
    missing_doc_type: missingDocType.value,
    order_type: orderType?.value, // investigate
    ordered_end_date: createdDateEnd,
    ordered_start_date: createdDateStart,
    orderShipmentTypes: formatArray(orderShipmentTypes),
    cold_store_ids: formatArray(coldstore),
    load_port_ids: formatArray(loadPort),
    dest_port_ids: formatArray(destPort),
    packer_ids: formatArray(packers),
    packer_invoice_number: vendorInvoiceNumber,
    packer_plant_ids: formatArray(packerPlants),
    packer_so_number: vendorSoNumber,
    page: currentPage || DEFAULT_PAGE,
    limit,
    product_types: formatArray(productTypes),
    purchasing_office: purchasingOffice?.value,
    shipmentPortMark: portMark,
    tags: formatArray(tags),
    user_id: buyer?.value,
    packer_plant_number: packerPlantNumber,
    sortColumns: sortColumns ? Object.values(sortColumns).map(itm => `${itm.columnKey}_${itm.direction}`) : '',
  };

  dispatch(fetchOrderHistory(searchFilters, token));
}
// --------------------------------------
// FORM
// --------------------------------------

export default function BulkUpdaterSearchForm(props) {
  const dispatch = useDispatch();
  const orderOptions = useSelector(selectOrderOptions);
  const buyerOptions = useSelector(selectBuyerOptions);
  const currentUser = useSelector(selectCurrentUser);

  const {
    businessOfficeOptions,
    formulaNameOptions,
    grinderOptions,
    inputProductOptions,
    missingDocTypeOptions,
    orderShipmentTypeOptions,
    coldstoreOptions,
    orderTypeOptions,
    packerOptions,
    packerPlantOptions,
    productTypeOptions,
    portOptions,
    tagOptions,
  } = orderOptions;
  const isColdstoreRequiredOptions = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];
  const formattedColdstoreOptions = [];
  coldstoreOptions.forEach(el => {
    formattedColdstoreOptions.push({ label: el.location_name, value: el.id });
  });
  const formattedPortOptions = [];
  portOptions.forEach(el => {
    formattedPortOptions.push({ label: el.name, value: el.id });
  });
  const { currentPage, limit, sortColumns, onSubmitSeach } = props;

  const [shouldSubmit, setShouldSubmit] = useState(false);

  // Handle form Submission
  const handleSubmit = useCallback(
    values => {
      setShouldSubmit(false);
      submitSearchForm(values, { dispatch, token: currentUser?.token, currentPage, limit, sortColumns });
      onSubmitSeach(values);
    },
    [currentPage, limit, currentUser?.token, sortColumns]
  );

  // Initialize Form + handle loading state
  const [initialValues, setInitialValues] = useState();
  // Form is loading if it is executing search or loading entities:
  const { isLoading } = orderOptions;
  useEffect(() => {
    if (isLoading) return;
    // Initialize Form
    setInitialValues(initialize());
    dispatch(fetchUsers());
  }, [isLoading]);

  const filteredGrinderOptions = grinderOptions.filter(
    grinder => grinder.delisted === false || grinder.delisted === null
  );
  const filteredInputProducts = inputProductOptions.filter(
    inputProduct => inputProduct.delisted === false || inputProduct.delisted === null
  );
  const filteredPackerPlantsOptions = packerPlantOptions.filter(
    packerPlant => packerPlant.delisted === false || packerPlant.delisted === null
  );

  // Form context includes options, butons, and helper functions
  // that need to be accessible from the form configuration.
  const context = {
    options: {
      buyerOptions,
      businessOfficeOptions,
      formulaNameOptions,
      grinderOptions: filteredGrinderOptions,
      inputProductOptions: filteredInputProducts,
      orderTypeOptions,
      orderShipmentTypeOptions,
      coldstoreOptions,
      formattedColdstoreOptions,
      formattedPortOptions,
      missingDocTypeOptions,
      packerOptions,
      packerPlantOptions: filteredPackerPlantsOptions,
      productTypeOptions,
      isColdstoreRequiredOptions,
      tagOptions,
      portOptions,
    },
  };

  // Dynamically re-submit form on results page change:
  useEffect(() => {
    if (!isNaN(currentPage)) {
      setShouldSubmit(true);
    }
  }, [currentPage]);

  useEffect(() => {
    if (!isNaN(limit)) {
      setShouldSubmit(true);
    }
  }, [limit]);

  useEffect(() => {
    if (!isNull(sortColumns)) {
      setShouldSubmit(true);
    }
  }, [sortColumns]);

  return (
    <>
      {orderOptions.isLoading || !initialValues ? (
        <Loading />
      ) : (
        <BoabForm
          {...{
            context,
            formConfig,
            initialValues,
            isLoading,
            onSubmit: handleSubmit,
            shouldSubmit,
            validationSchema,
          }}
        />
      )}
    </>
  );
}

BulkUpdaterSearchForm.propTypes = propTypes;
