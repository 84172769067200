import React, { useEffect } from 'react';
import { HStack, Text, Card, Box, Grid, GridItem } from '@chakra-ui/react';
import { ascending, format } from 'd3';
import { useDispatch, useSelector } from 'react-redux';

import ColumnChart from '../../../components/analyticDashboard/marketsDetails/ColumnChart';
import { flatten, uniq } from '../../../modules/utils';
import DetailPageHeading from '../../../shared/DetailPageHeading/DetailPageHeading';
import LoadingOverlay from '../../../shared/LoadingOverlay/LoadingOverlay';
import TabBar from '../../../shared/TabBar';
import { MARKET_FOCUS, TIME_MODE, VOLUME_UNITS } from '../../../slices/markets-details/marketsDetailsConfig';
import {
  fetchMarketsVolumeDetailData,
  selectMarketsVolumeDetailData,
  selectMarketsVolumeDetailError,
  selectMarketsVolumeDetailFetchParameters,
  selectMarketsVolumeDetailFilters,
  selectMarketsVolumeDetailLoading,
  selectMarketsVolumeDetailTimeMode,
} from '../../../slices/markets-details/selectors/selectMarketsVolumeDetailData';
import { updateUnits } from '../../../slices/markets-details/volumeDetails';
import styles from './Volume.module.css';
import VolumeFilters from './VolumeFilters';
import VolumeSidebar from './VolumeSidebar';
import { RadioGroup, Radio } from '@/components/ui/radio';
import { Alert } from '@/components/ui/alert';

export default function MarketsVolumeDetail() {
  const dispatch = useDispatch();
  const loading = useSelector(selectMarketsVolumeDetailLoading);
  const error = useSelector(selectMarketsVolumeDetailError);
  const data = useSelector(selectMarketsVolumeDetailData);
  const filters = useSelector(selectMarketsVolumeDetailFilters);
  const fetchParameters = useSelector(selectMarketsVolumeDetailFetchParameters);
  const timeMode = useSelector(selectMarketsVolumeDetailTimeMode);

  useEffect(() => {
    dispatch(fetchMarketsVolumeDetailData(fetchParameters));
  }, [dispatch, fetchParameters]);

  const changeUnits = React.useCallback(event => dispatch(updateUnits(event.value)), [dispatch]);

  const calculateTotalVolume = chartData => {
    return chartData.series.reduce((total, { values }) => {
      return (
        total +
        values.reduce((seriesTotal, [, value]) => {
          return seriesTotal + value;
        }, 0)
      );
    }, 0);
  };

  if (error) {
    return (
      <div>
        <Alert title="Error" status="error">
          {error}
        </Alert>
      </div>
    );
  }

  const labelGenerator = () => {
    const allChartLabels = data.map(({ series }) => {
      const allSeriesLabels = series.map(({ labels }) => labels.map(label => label));
      return flatten(allSeriesLabels);
    });
    return uniq(flatten(allChartLabels).sort(ascending));
  };

  const minMaxGenerator = () => {
    const chartMaximums = data.map(({ series }) => {
      return Math.max(...flatten(series.map(({ values }) => values.map(([, value]) => value))));
    });

    return {
      min: 0,
      max: Math.max(...chartMaximums),
    };
  };

  const charts = data.filter(({ key }) => {
    if (timeMode === TIME_MODE.HISTORICAL) {
      if (filters.aggregateBySelection.length === 0) {
        return true;
      }
      return filters.aggregateBySelection.includes(key);
    }
    return true;
  });

  const isShowingYearSelections = timeMode === TIME_MODE.YEAR_ON_YEAR;
  const isShowingGroupBySelections = !isShowingYearSelections && !!filters.groupBy;
  const isShowingAggregateDropdown = !isShowingYearSelections && filters.market !== MARKET_FOCUS.DOMESTIC;
  const isShowingSidebar = isShowingYearSelections || isShowingGroupBySelections || isShowingAggregateDropdown;

  const colors = ['#09AFA3', '#FF1577', '#5039B5', '#FC5D36', '#00B5F2', '#FED000', '#0B1435', '#AB2424'];

  const allSeries = uniq(flatten(charts.map(({ series }) => series.map(({ key }) => key)))).sort(ascending);

  let coloredSeries = filters.groupBySelection.length === 0 ? allSeries : filters.groupBySelection;

  if (timeMode === TIME_MODE.YEAR_ON_YEAR) {
    coloredSeries = filters.yearSelection.length === 0 ? allSeries : filters.yearSelection;
  }

  const seriesToColorMap = allSeries.reduce((mapping, series, index) => {
    if (coloredSeries.includes(series)) {
      mapping[series] = colors[index % colors.length];
    }
    return mapping;
  }, {});

  return (
    <>
      <Box>
        <Box>
          <TabBar />
          <DetailPageHeading title="Beef Volume" />
          <Box className={styles.filterRow}>
            <Box>
              <VolumeFilters />
            </Box>
          </Box>
          <Box>
            <Box>
              <Card.Root className={styles.contentCard}>
                <Card.Body>
                  <Box>
                    <Box>
                      <Text>
                        <strong>Volume</strong> - {filters.volume}
                      </Text>
                      <RadioGroup
                        className={styles.radioGroup}
                        value={filters.units}
                        onValueChange={changeUnits}
                        style={{ marginLeft: 10 }}
                      >
                        <HStack alignItems="center" justifyContent="end">
                          <Radio key={VOLUME_UNITS.KG} value={VOLUME_UNITS.KG}>
                            {VOLUME_UNITS.KG}
                          </Radio>
                          <Radio key={VOLUME_UNITS.LOADS} value={VOLUME_UNITS.LOADS}>
                            {VOLUME_UNITS.LOADS}
                          </Radio>
                        </HStack>
                      </RadioGroup>
                    </Box>
                  </Box>
                  <Grid templateColumns="repeat(24, 1fr)" gap={2}>
                    <GridItem colSpan={filters.groupBy ? 19 : 24} flex="auto">
                      {charts.map((chartData, index) => {
                        const seriesFilteredChartData = {
                          ...chartData,
                          series: chartData.series.filter(({ key }) => {
                            if (timeMode === TIME_MODE.HISTORICAL) {
                              if (filters.groupBySelection.length === 0) {
                                return true;
                              }
                              return filters.groupBySelection.includes(key);
                            }
                            if (filters.yearSelection.length === 0) {
                              return true;
                            }
                            return filters.yearSelection.includes(key);
                          }),
                        };

                        const leftTitle = chartData.key;
                        const rightTitle = (
                          <span className={styles.volumeTotal}>
                            TOTAL <strong>{format(',.3s')(calculateTotalVolume(seriesFilteredChartData))}</strong>{' '}
                            {filters.units}
                          </span>
                        );

                        return (
                          <ColumnChart
                            key={index}
                            data={seriesFilteredChartData}
                            filters={filters}
                            colors={seriesToColorMap}
                            leftTitle={leftTitle}
                            rightTitle={rightTitle}
                            labelGenerator={labelGenerator}
                            minMaxGenerator={minMaxGenerator}
                            mode={timeMode === TIME_MODE.HISTORICAL ? 'column' : 'line'}
                          />
                        );
                      })}
                    </GridItem>
                    {isShowingSidebar && (
                      <GridItem colSpan={5} flex="auto">
                        <VolumeSidebar
                          isShowingGroupBySelections={isShowingGroupBySelections}
                          isShowingAggregateDropdown={isShowingAggregateDropdown}
                          isShowingYearSelections={isShowingYearSelections}
                          colors={seriesToColorMap}
                        />
                      </GridItem>
                    )}
                  </Grid>
                </Card.Body>
              </Card.Root>
            </Box>
          </Box>
        </Box>
      </Box>
      <LoadingOverlay loading={loading} />
    </>
  );
}
