import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ascending } from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { CheckboxGroup, Grid } from '@chakra-ui/react';

import FilterTitle from '../../../components/analyticDashboard/marketsDetails/FilterTitle';
import ColoredCheckboxGroup from '../../../components/analyticDashboard/marketsDetails/ColoredCheckboxGroup';
import { uniq, flatten } from '../../../modules/utils';
import styles from './Volume.module.css';

import { AGGREGATE_BY, GROUP_BY } from '../../../slices/markets-details/marketsDetailsConfig';
import {
  selectMarketsVolumeDetailData,
  selectMarketsVolumeDetailFilters,
  selectAggregationOptions,
} from '../../../slices/markets-details/selectors/selectMarketsVolumeDetailData';
import {
  updateGroupBySelection,
  updateAggregateBySelection,
  updateAggregateBy,
  updateYearSelection,
} from '../../../slices/markets-details/volumeDetails';

export default function VolumeSidebar({
  isShowingGroupBySelections,
  isShowingAggregateDropdown,
  isShowingYearSelections,
  colors,
}) {
  const dispatch = useDispatch();
  const data = useSelector(selectMarketsVolumeDetailData);
  const filters = useSelector(selectMarketsVolumeDetailFilters);
  const aggregationOptions = useSelector(selectAggregationOptions);

  const changeGroupBySelection = React.useCallback(
    checkedValues => dispatch(updateGroupBySelection(checkedValues)),
    [dispatch]
  );

  const changeYearSelection = React.useCallback(
    checkedValues => dispatch(updateYearSelection(checkedValues)),
    [dispatch]
  );

  const changeAggregateBySelection = React.useCallback(
    checkedValues => dispatch(updateAggregateBySelection(checkedValues)),
    [dispatch]
  );

  const changeAggregateBy = React.useCallback(
    ({ key }) => dispatch(updateAggregateBy(key === '--' ? null : key)),
    [dispatch]
  );

  const seriesKeys = uniq(flatten(data.map(({ series }) => series.map(({ key }) => key)))).sort(ascending);

  const isGroupingByCountry = filters.GROUP_BY === GROUP_BY.COUNTRY;
  const isShowingCountryAggregationOption = isShowingAggregateDropdown && isGroupingByCountry;

  const aggregateByMenu = (
    <Menu onClick={changeAggregateBy}>
      <Menu.Item key="--">--</Menu.Item>
      {!isShowingCountryAggregationOption && <Menu.Item key={AGGREGATE_BY.COUNTRY}>{AGGREGATE_BY.COUNTRY}</Menu.Item>}
      <Menu.Item key={AGGREGATE_BY.DOMESTIC_V_EXPORT}>{AGGREGATE_BY.DOMESTIC_V_EXPORT}</Menu.Item>
    </Menu>
  );

  let aggregateBySelectionTitle = '';
  if (filters.aggregateBy === AGGREGATE_BY.COUNTRY) {
    aggregateBySelectionTitle = `${aggregationOptions.length} Countries`;
  }

  const GroupByCheckboxGroup = isShowingYearSelections ? Checkbox.Group : ColoredCheckboxGroup;

  return (
    <>
      {isShowingYearSelections && (
        <>
          <FilterTitle marginTop={0}>Year</FilterTitle>
          <ColoredCheckboxGroup
            className={styles.checkboxListCustom}
            options={seriesKeys.map(key => {
              return {
                label: format(new Date(+key), 'yyyy'),
                value: key,
                color: colors[key],
              };
            })}
            value={filters.yearSelection}
            onChange={changeYearSelection}
          />
        </>
      )}
      {isShowingGroupBySelections && (
        <>
          <FilterTitle marginTop={isShowingYearSelections ? 11 : 0}>{filters.groupBy}</FilterTitle>
          <GroupByCheckboxGroup
            className={styles.checkboxListCustom}
            options={seriesKeys.map(key => {
              return {
                label: key,
                value: key,
                color: colors[key],
              };
            })}
            value={filters.groupBySelection}
            onChange={changeGroupBySelection}
          />
        </>
      )}
      {isShowingAggregateDropdown && (
        <>
          <FilterTitle marginTop={isShowingGroupBySelections ? 11 : 0}>Aggregate By</FilterTitle>
          <Dropdown overlay={aggregateByMenu} trigger="click">
            <span className={styles.dropdown}>
              {filters.aggregateBy || '--'} <MdOutlineKeyboardArrowDown />
            </span>
          </Dropdown>

          {!!aggregationOptions.length && (
            <>
              <Grid>{aggregateBySelectionTitle && <FilterTitle>{aggregateBySelectionTitle}</FilterTitle>}</Grid>
              <CheckboxGroup
                className={styles.checkboxList}
                options={aggregationOptions.map(key => {
                  return {
                    label: key,
                    value: key,
                  };
                })}
                defaultValue={aggregationOptions}
                onChange={changeAggregateBySelection}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

VolumeSidebar.propTypes = {
  isShowingGroupBySelections: PropTypes.bool,
  isShowingAggregateDropdown: PropTypes.bool,
  isShowingYearSelections: PropTypes.bool,
  colors: PropTypes.any,
};
