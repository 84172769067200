import React from 'react';
import { GridItem, Grid } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateActiveFocus,
  // updateActiveProtein,
  updateActiveCurrency,
  updateTimePeriod,
} from '../../../slices/markets/marketReducers';
import {
  selectActiveFocus,
  // selectActiveProtein,
  selectActiveCurrency,
  selectActiveMarket,
  selectMarketsTimePeriod,
} from '../../../slices/markets/selectors/selectBasicMarketData';

import FilterFocus from '../../../components/analyticDashboard/markets/FilterFocus/FilterFocus';
import FilterCurrency from '../../../components/analyticDashboard/markets/FilterCurrency/FilterCurrency';
// import FilterProtein from '../../../components/analyticDashboard/markets/FilterProtein/FilterProtein';
import FilterTimePeriod from '../../../components/analyticDashboard/markets/FilterTimePeriod/FilterTimePeriod';

export default function MarketsFilters(/* props */) {
  const dispatch = useDispatch();

  const activeMarket = useSelector(selectActiveMarket);
  const activeFocus = useSelector(selectActiveFocus);
  // const activeProtein = useSelector(selectActiveProtein);
  const activeCurrency = useSelector(selectActiveCurrency);
  const timePeriod = useSelector(selectMarketsTimePeriod);

  const handleActiveFocusChange = React.useCallback(
    newVal => {
      dispatch(updateActiveFocus(newVal));
    },
    [dispatch]
  );

  // const handleActiveProteinChange = React.useCallback(
  //   newVal => {
  //     dispatch(updateActiveProtein(newVal));
  //   },
  //   [dispatch]
  // );

  const handleActiveCurrencyChange = React.useCallback(
    newVal => {
      dispatch(updateActiveCurrency(newVal));
    },
    [dispatch]
  );

  const handleTimePeriodChange = React.useCallback(
    newVal => {
      dispatch(updateTimePeriod(newVal));
    },
    [dispatch]
  );

  return (
    <Grid gap={4} templateColumns="repeat(24, 1fr)" alignItems="center" textAlign="center">
      {activeMarket.allowExportFocus && (
        <GridItem colSpan={6}>
          <FilterFocus activeFocus={activeFocus} onActiveFocusChange={handleActiveFocusChange} />
        </GridItem>
      )}
      <GridItem colSpan={6}>
        {/* <FilterProtein activeProtein={activeProtein} onActiveProteinChange={handleActiveProteinChange} /> */}
        <FilterCurrency activeCurrency={activeCurrency} onActiveCurrencyChange={handleActiveCurrencyChange} />
      </GridItem>
      <GridItem colSpan={12}>
        <FilterTimePeriod timePeriod={timePeriod} onTimePeriodChange={handleTimePeriodChange} />
      </GridItem>
    </Grid>
  );
}
