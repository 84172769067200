import { Box, Flex, Spacer, VStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPurchaseOrders } from '../../../../actions/actions_orders';
import Loading from '../../../../components/basic/Loading';
import ResultsGrid from '../../../../components/basic/ResultsGrid';
import SelectField from '../../../../components/basic/SelectField';
import Button from '../../../../components/core/Button';
import Card from '../../../../components/core/Card';

import OrderActionsFormatter from '../../../../components/gridHelpers/OrderActionsFormatter';
import { selectOrderHistorySearchResult } from '../../../../reducers/reducer_orders';
import { selectCurrentUser } from '../../../../reducers/reducer_user';
import views, { rowHeight, rowKeyGetter, rowClass } from '../../bulk-updater-views';
import BulkUpdaterSearchForm from './BulkUpdaterSearchForm';
import { exportOrdersPOC, submitExport } from './helpers';
import Constants from '../../../../Constants';
import IUnleashClassFlagProvider from '../../../../components/unleash/UnleashClassFlagProvider';
import { fetchEntitiesData, selectEntities } from '../../../../slices/masterData/entityManagerSlice';
import { Alert } from '../../../../components/ui/alert';
import { MdDownload } from 'react-icons/md';

let MAX_EDIT_COUNT = 50;

const BulkUpdaterSearch = ({ view, setView, onClickEditModeButton }) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState();
  const [sortColumns, setSortColumns] = useState();
  const [rows, setRows] = useState([]);
  const [showPOsSelectionWarning, setShowPOsSelectionWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLimitWarningMessage, setShowLimitWarningMessage] = useState(false);

  // these are the po ids checked in the grid
  const [selectedRows, setSelectedRows] = useState(new Set());
  // these are the po items checked in the grid
  const [selectedResults, setSearchResults] = useState([]);
  const [limit, setLimit] = useState({ label: 50, value: 50 });

  const { pagination, results, isSearching, hasExecutedSearchs } = useSelector(selectOrderHistorySearchResult);

  useEffect(() => {
    if (results) {
      setRows(JSON.parse(JSON.stringify(results)));
    }
  }, [results]);
  useEffect(() => {
    dispatch(fetchEntitiesData(['form_fields']));
  }, []);
  const entities = useSelector(selectEntities) || {};
  const form_fields = entities.form_fields ? entities.form_fields.entities.filter(n => n.type === 'order_view') : [];
  const form_views = [];
  form_fields.map(field => form_views.push(field.value));
  useEffect(() => {
    // remove
    const newSelectedSearchResults = selectedResults.filter(po => selectedRows.has(po.id));
    // add
    selectedRows.forEach(id => {
      if (!newSelectedSearchResults.find(po => po.id === id)) {
        newSelectedSearchResults.push(rows.find(po => po.id === id));
      }
    });
    setSearchResults(newSelectedSearchResults);
    if (selectedRows.size > limit.value) {
      setShowLimitWarningMessage(true);
    } else {
      setShowLimitWarningMessage(false);
    }
    if (selectedRows.size > 0) {
      setShowPOsSelectionWarning(false);
    }
  }, [selectedRows]);
  const defaultLimit = { label: 50, value: 50 };
  const notDataFound = results.length === 0 && !isSearching && hasExecutedSearchs;
  const currentUser = useSelector(selectCurrentUser);
  const [searchFormValues, setSearchFormValues] = useState();
  const handleSearchSubmit = values => {
    setSearchFormValues(values);
  };

  const handleExportSubmit = () => {
    setIsLoading(true);
    const updatedSearchFormValues = {
      ...searchFormValues,
      limit: limit ? limit.value * pagination.pages : defaultLimit.value * pagination.pages,
    };

    submitExport(updatedSearchFormValues, currentUser?.token)
      .then(response => response.json())
      .then(json => {
        exportOrdersPOC(json.items);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false); // Stop loading
      });
  };
  return (
    <VStack gap="26px" align="stretch">
      <Box pb="34px" pt="14px" px="54px" w="100%">
        <BulkUpdaterSearchForm
          currentPage={currentPage}
          limit={limit ? limit.value : defaultLimit.value}
          sortColumns={sortColumns}
          onSubmitSeach={handleSearchSubmit}
        />
        {notDataFound && (
          <Alert
            status="warning"
            bg="warningBg"
            border="1px solid"
            borderColor="warningBorder"
            borderRadius="8px"
            mb="30px"
            w="720px"
            title="No results"
          >
            No results found for your search, please try again.
          </Alert>
        )}
        {isSearching && <Loading />}
        {!!rows.length && (
          <>
            <Card
              {...{
                my: '20px',
                pt: '17px',
                pb: '33px',
                px: '27px',
                borderColor: 'gray.400',
                backgroundColor: 'graySubtle',
                text: 'PO List',
              }}
            >
              {showPOsSelectionWarning && !showLimitWarningMessage && (
                <Alert
                  status="warning"
                  bg="warningBg"
                  border="1px solid"
                  borderColor="warningBorder"
                  borderRadius="8px"
                  mb="30px"
                  w="720px"
                  title="No rows selected for edit mode."
                />
              )}
              {showLimitWarningMessage && (
                <Alert
                  status="warning"
                  bg="warningBg"
                  border="1px solid"
                  borderColor="warningBorder"
                  borderRadius="8px"
                  mb="30px"
                  w="720px"
                  title={`Edit Mode allows only ${limit.value} rows to be edited at a time. Please select no more than ${limit.value} rows to edit.`}
                />
              )}
              <Flex mb="20px">
                <Box w="135px">
                  <SelectField
                    {...{
                      name: 'view',
                      options: Object.values(views).filter(
                        v => form_views.includes(v.label) || v.label === 'Default View'
                      ),
                      onChange: option => setView(views[option.value]),
                      closeMenuOnSelect: true,
                      value: view,
                    }}
                  />
                </Box>
                <Box w="135px" ml="15px">
                  <SelectField
                    {...{
                      name: 'limit',
                      options: Constants.BULK__PAGE_LIMIT,
                      onChange: option => {
                        setLimit(option);
                        MAX_EDIT_COUNT = option.value;
                        setSelectedRows(new Set());
                      },
                      closeMenuOnSelect: true,
                      value: limit || defaultLimit,
                    }}
                  />
                </Box>
                <Spacer />
                <Button
                  // colorScheme="actionSecondary"
                  width="110px"
                  onClick={() => {
                    if (
                      selectedRows.size > MAX_EDIT_COUNT ||
                      (pagination?.pages > MAX_EDIT_COUNT && selectedRows.size === 0)
                    ) {
                      setShowPOsSelectionWarning(true);
                      return;
                    }
                    setShowPOsSelectionWarning(false);
                    dispatch(resetPurchaseOrders());
                    if (selectedResults.length === 0 && pagination?.pages <= MAX_EDIT_COUNT) {
                      onClickEditModeButton(JSON.parse(JSON.stringify(rows)));
                    } else {
                      onClickEditModeButton(selectedResults);
                    }
                  }}
                  disabled={rows.length === 0}
                >
                  Edit Mode
                </Button>
                <Button
                  colorScheme="actionSecondary"
                  size="md"
                  variant="ghost"
                  onClick={handleExportSubmit}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  <MdDownload size="24" color="var(--chakra-colors-actionSecondary)" />
                </Button>
              </Flex>
              <ResultsGrid
                id="bulk-updater-search-grid"
                className="bulk-updater__results-grid"
                columns={view.columns.map(({ editor, ...c }) => ({ ...c }))}
                rows={rows}
                rowKeyGetter={rowKeyGetter}
                onGridRowUpdate={setRows}
                selectedRows={selectedRows}
                onRowSelect={setSelectedRows}
                rowHeight={rowHeight}
                pagination={pagination}
                onPageChange={newPage => {
                  const page = newPage?.selected || 0;
                  // Add 1 because it defaults to zero:
                  setCurrentPage(page + 1);
                }}
                height={400}
                minHeight={400}
                disableSelect
                rowClass={rowClass}
                sortColumns={sortColumns}
                onSortColumnsChange={_sortColumns => {
                  setSortColumns([..._sortColumns]);
                }}
              />
            </Card>
          </>
        )}
      </Box>
      <OrderActionsFormatter.Modals />
    </VStack>
  );
};

BulkUpdaterSearch.propTypes = {
  view: PropTypes.objectOf(Object),
  onClickEditModeButton: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
};

const BulkUpdaterSearchUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.BULK_UPDATER}
        show={<BulkUpdaterSearch {...props} />}
        hide={<h1>Feature Not Enabled</h1>}
      />
    </>
  );
};

export default BulkUpdaterSearchUnleashController;
