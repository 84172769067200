import React from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import {
  renderCard,
  renderGrid,
  renderText,
  renderButton,
  renderAccordion,
} from '../../../../../components/lib/formLoader';
import { Tooltip } from '@/components/ui/tooltip';
import { IconButton } from '@chakra-ui/react';
import { IoInformationCircleOutline } from 'react-icons/io5';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const today = moment();

export const validationSchema = Yup.object().shape({
  grinderPoNumber: Yup.string(),
  grinderPoNumberRange: Yup.string(),
  internalPoNumber: Yup.string(),
  vendorSoNumber: Yup.string(),
  vendorInvoiceNumber: Yup.string(),
  buyer: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .nullable(true),
  deliveryDateStart: Yup.string(),
  deliveryDateEnd: Yup.string(),
  createdDateStart: Yup.string(),
  createdDateEnd: Yup.string(),
  grinders: Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
    })
  ),
  purchasingOffice: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .nullable(true),
  packers: Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
    })
  ),
  packerPlants: Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
    })
  ),
  packerPlantNumber: Yup.string(),
  inputProducts: Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
    })
  ),
  productType: Yup.object().shape({
    value: Yup.string(),
  }),
  orderType: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .nullable(true),
  isColdstoreRequired: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .nullable(true),
  tags: Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
    })
  ),
  bolNumber: Yup.string(),
  containerNumber: Yup.string(),
  bookingReferenceNumber: Yup.string(),
  portMark: Yup.string(),
  vesselName: Yup.string(),
  voyageNumber: Yup.string(),
  etdDateStart: Yup.string(),
  etdDateEnd: Yup.string(),
  etaDateEnd: Yup.string(),
  etaDateStart: Yup.string(),
  orderShipmentTypes: Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
    })
  ),
  coldstore: Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
    })
  ),
  missingDocType: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .nullable(true),
  loadPort: Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
    })
  ),
  destPort: Yup.array().of(
    Yup.object().shape({
      value: Yup.string(),
    })
  ),
});

export function initialize() {
  return {
    grinderPoNumber: '',
    grinderPoNumberRange: '',
    internalPoNumber: '',
    vendorSoNumber: '',
    vendorInvoiceNumber: '',
    buyer: {},
    deliveryDateStart: '',
    deliveryDateEnd: '',
    createdDateStart: '',
    createdDateEnd: '',
    grinders: [],
    purchasingOffice: {},
    packers: [],
    packerPlants: [],
    packerPlantNumber: '',
    inputProducts: [],
    productTypes: [],
    orderType: {},
    isColdstoreRequired: {},
    tags: [],
    bolNumber: '',
    containerNumber: '',
    bookingReferenceNumber: '',
    portMark: '',
    vesselName: '',
    voyageNumber: '',
    etdDateStart: '',
    etdDateEnd: '',
    etaDateStart: '',
    etaDateEnd: '',
    orderShipmentTypes: [],
    coldstore: [],
    loadPort: [],
    destPort: [],
    missingDocType: {},
  };
}

const quickFilters = {
  key: 'quick-filters-row',
  renderComponent: renderGrid,
  templateColumns: 'repeat(12, 1fr)',
  gap: 7,
  pb: '20px',
  children: [
    {
      key: 'quick-filters-label',
      text: 'Quick Filters',
      renderComponent: renderText,
      color: 'accent.one.default',
      fontWeight: 'bold',
      fontSize: '15px',
      pt: '20px',
      textAlign: 'left',
      colSpan: 2,
    },
    {
      key: 'quick-filters-button-container',
      colSpan: 10,
      children: [
        {
          key: 'quick-filters-button-1',
          renderComponent: renderButton,
          borderColor: 'secondary.900',
          color: 'secondary.900',
          variant: 'ghost',
          height: '28px',
          text: 'Created Last 24 hours',
          mt: '27px',
          mr: '20px',
          onClick: (e, context) => {
            const { setFieldValue, submitForm, values, resetForm } = context;
            const startDate = moment().subtract(1, 'days').format(DEFAULT_DATE_FORMAT);
            resetForm();
            setFieldValue('createdDateStart', startDate);
            submitForm(values);
          },
        },
        {
          key: 'quick-filters-button-2',
          renderComponent: renderButton,
          borderColor: 'secondary.900',
          color: 'secondary.900',
          variant: 'ghost',
          height: '28px',
          text: 'Delivered Last Week',
          mt: '27px',
          mr: '20px',
          onClick: (e, context) => {
            const { setFieldValue, submitForm, values, resetForm } = context;
            const startDate = moment().subtract(7, 'days').startOf('day').format(DEFAULT_DATE_FORMAT);
            const endDate = moment().startOf('day').format(DEFAULT_DATE_FORMAT);
            resetForm();
            setFieldValue('deliveryDateStart', startDate);
            setFieldValue('deliveryDateEnd', endDate);
            submitForm(values);
          },
        },
        {
          key: 'quick-filters-button-3',
          renderComponent: renderButton,
          borderColor: 'secondary.900',
          color: 'secondary.900',
          variant: 'ghost',
          height: '28px',
          text: 'Delivered Next Week',
          mt: '27px',
          mr: '20px',
          onClick: (e, context) => {
            const { setFieldValue, submitForm, values, resetForm } = context;
            const startDate = moment().startOf('day').format(DEFAULT_DATE_FORMAT);
            const endDate = moment().add(7, 'days').startOf('day').format(DEFAULT_DATE_FORMAT);
            resetForm();
            setFieldValue('deliveryDateStart', startDate);
            setFieldValue('deliveryDateEnd', endDate);
            submitForm(values);
          },
        },
        {
          key: 'quick-filters-button-4',
          renderComponent: renderButton,
          borderColor: 'secondary.900',
          color: 'secondary.900',
          variant: 'ghost',
          height: '28px',
          text: 'Delivered Next Two Weeks',
          mt: '27px',
          onClick: (e, context) => {
            const { setFieldValue, submitForm, values, resetForm } = context;
            const startDate = moment().startOf('day').format(DEFAULT_DATE_FORMAT);
            const endDate = moment().add(14, 'days').startOf('day').format(DEFAULT_DATE_FORMAT);
            resetForm();
            setFieldValue('deliveryDateStart', startDate);
            setFieldValue('deliveryDateEnd', endDate);
            submitForm(values);
          },
        },
      ],
    },
  ],
};

const poFields = {
  key: 'po-fields-row',
  renderComponent: renderGrid,
  templateColumns: 'repeat(12, 1fr)',
  gap: 7,
  pb: '20px',
  children: [
    {
      key: 'po-fields-row-label',
      text: 'By PO#',
      renderComponent: renderText,
      color: 'accent.one.default',
      fontWeight: 'bold',
      fontSize: '15px',
      pt: '20px',
      textAlign: 'left',
      colSpan: 2,
    },
    {
      key: 'grinderPoNumber',
      label: (
        <>
          Grinder PO#&nbsp;
          <Tooltip
            content="Specifies the purchase order number assigned by the grinder."
            positioning={{ placement: 'right-end' }}
          >
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'grinderPoNumber',
      fieldType: 'input',
      colSpan: 2,
      width: '100%',
    },
    {
      key: 'fmgPONumber',
      label: (
        <>
          PO#&nbsp;
          <Tooltip
            content="Purchase Order Number for tracking and managing the order."
            positioning={{ placement: 'right-end' }}
          >
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'internalPoNumber',
      fieldType: 'input',
      colSpan: 2,
    },
    {
      key: 'vendorSoNumber',
      label: (
        <>
          Packer SO#&nbsp;
          <Tooltip
            content="Specifies the sales order number assigned by the packer."
            positioning={{ placement: 'right-end' }}
          >
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'vendorSoNumber',
      fieldType: 'input',
      colSpan: 2,
    },
    {
      key: 'vendorInvoiceNumber',
      label: (
        <>
          Packer Invoice#&nbsp;
          <Tooltip
            content="Specifies the invoice number assigned by the packer."
            positioning={{ placement: 'right-end' }}
          >
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'vendorInvoiceNumber',
      fieldType: 'input',
      colSpan: 2,
    },
    {
      key: 'buyer',
      label: (
        <>
          Buyer&nbsp;
          <Tooltip
            content="Identifies the individual or organization purchasing the product."
            positioning={{ placement: 'right-end' }}
          >
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'buyer',
      optionsKey: 'buyer',
      fieldType: 'select',
      colSpan: 2,
    },
  ],
};

const dateFields = {
  key: 'date-fields-row',
  renderComponent: renderGrid,
  templateColumns: 'repeat(12, 1fr)',
  gap: 7,
  pb: '20px',
  children: [
    {
      key: 'date-fields-label',
      text: 'By Dates',
      renderComponent: renderText,
      color: 'accent.one.default',
      fontWeight: 'bold',
      fontSize: '15px',
      pt: '20px',
      textAlign: 'left',
      colSpan: 2,
    },
    {
      label: (
        <>
          Delivery From&nbsp;
          <Tooltip content="The starting date of the delivery period." positioning={{ placement: 'right-end' }}>
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'deliveryDateStart',
      fieldType: 'date',
      dateFormat: 'YYYY-MM-DD',
      colSpan: 2,
    },
    {
      label: (
        <>
          Delivery To&nbsp;
          <Tooltip content="The ending date of the delivery period." positioning={{ placement: 'right-end' }}>
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'deliveryDateEnd',
      fieldType: 'date',
      dateFormat: 'YYYY-MM-DD',
      colSpan: 2,
    },
    {
      label: (
        <>
          Created From&nbsp;
          <Tooltip content="The starting date when the order was created." positioning={{ placement: 'right-end' }}>
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'createdDateStart',
      fieldType: 'date',
      dateFormat: 'YYYY-MM-DD',
      colSpan: 2,
      maxDate: new Date(),
    },
    {
      label: (
        <>
          Created To&nbsp;
          <Tooltip content="The ending date when the order was created." positioning={{ placement: 'right-end' }}>
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'createdDateEnd',
      fieldType: 'date',
      dateFormat: 'YYYY-MM-DD',
      colSpan: 2,
      maxDate: new Date(),
    },
    {
      key: 'grinderPoNumberRange',
      label: (
        <>
          Grinder PO# Range&nbsp;
          <Tooltip content="Range of purchase order numbers for grinders." positioning={{ placement: 'right-end' }}>
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'grinderPoNumberRange',
      fieldType: 'input',
      colSpan: 2,
    },
  ],
};

const supplierOrCustomerFields = {
  key: 'supplier-customer-fields-row',
  renderComponent: renderGrid,
  templateColumns: 'repeat(12, 1fr)',
  gap: 7,
  pb: '30px',
  children: [
    {
      key: 'supplier-cusomer-fields-label',
      text: 'Supplier / Customer',
      renderComponent: renderText,
      color: 'accent.one.default',
      fontWeight: 'bold',
      fontSize: '15px',
      pt: '20px',
      textAlign: 'left',
      colSpan: 2,
    },
    {
      label: (
        <>
          Grinder&nbsp;
          <Tooltip
            content="Specifies the grinder used to ensure proper equipment and processes."
            positioning={{ placement: 'right-end' }}
          >
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'grinders',
      optionsKey: 'grinder',
      fieldType: 'select',
      isMulti: true,
      colSpan: 2,
    },
    {
      label: (
        <>
          Purchasing Offices&nbsp;
          <Tooltip content="Offices responsible for procurement." positioning={{ placement: 'right-end' }}>
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'purchasingOffice',
      optionsKey: 'businessOffice',
      fieldType: 'select',
      colSpan: 2,
    },
    {
      label: (
        <>
          Packer&nbsp;
          <Tooltip
            content="Specifies the packer responsible for handling and tracking the product."
            positioning={{ placement: 'right-end' }}
          >
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'packers',
      fieldType: 'select',
      optionsKey: 'packer',
      isMulti: true,
      colSpan: 2,
    },
    {
      label: (
        <>
          Est Name&nbsp;
          <Tooltip content="Name of the establishment involved in the order." positioning={{ placement: 'right-end' }}>
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'packerPlants',
      fieldType: 'select',
      optionsKey: 'packerPlant',
      isMulti: true,
      colSpan: 2,
    },
    {
      label: (
        <>
          Est #&nbsp;
          <Tooltip content="Unique identifier number for the establishment." positioning={{ placement: 'right-end' }}>
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'packerPlantNumber',
      fieldType: 'input',
      colSpan: 2,
    },
  ],
};

const orderDetailFields = {
  key: 'order-detail-fields-row',
  renderComponent: renderGrid,
  templateColumns: 'repeat(12, 1fr)',
  gap: 7,
  pb: '20px',
  children: [
    {
      key: 'order-detail-fields-label',
      text: 'Order Details',
      renderComponent: renderText,
      color: 'accent.one.default',
      fontWeight: 'bold',
      fontSize: '15px',
      pt: '20px',
      textAlign: 'left',
      colSpan: 2,
    },
    {
      label: (
        <>
          Product&nbsp;
          <Tooltip
            content="Select the specific product from the dropdown list."
            positioning={{ placement: 'right-end' }}
          >
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
        </>
      ),
      name: 'inputProducts',
      fieldType: 'select',
      optionsKey: 'inputProduct',
      isMulti: true,
      colSpan: 2,
    },
    {
      label: (
        <>
          Product Type&nbsp;
          <Tooltip
            content="Specifies whether the order is for Beef or Pork to categorize the meat product."
            positioning={{ placement: 'right-end' }}
          >
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'productTypes',
      fieldType: 'select',
      optionsKey: 'productType',
      isMulti: true,
      colSpan: 2,
    },
    {
      label: (
        <>
          Order Type&nbsp;
          <Tooltip
            content="Specifies the nature of the order, such as Spot or Contract."
            positioning={{ placement: 'right-end' }}
          >
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'orderType',
      fieldType: 'select',
      optionsKey: 'orderType',
      colSpan: 2,
    },
    {
      label: (
        <>
          Tags&nbsp;
          <Tooltip content="Specifies the tags added for the order." positioning={{ placement: 'right-end' }}>
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'tags',
      optionsKey: 'tag',
      fieldType: 'select',
      isMulti: true,
      isCreatable: true,
      colSpan: 2,
    },
    {
      label: (
        <>
          Coldstore Required&nbsp;
          <Tooltip
            content="Specifies if cold storage is required for the order."
            positioning={{ placement: 'right-end' }}
          >
            <IconButton
              width="14px"
              height="14px"
              padding="0"
              minW="auto"
              borderRadius="50%"
              color="#878787"
              variant="unstyled"
            >
              <IoInformationCircleOutline size="14px" />
            </IconButton>
          </Tooltip>
          :
        </>
      ),
      name: 'isColdstoreRequired',
      fieldType: 'select',
      optionsKey: 'isColdstoreRequired',
      colSpan: 2,
    },
  ],
};

const shipmentDetailFields = [
  {
    key: 'shipment-detail-fields-row',
    renderComponent: renderGrid,
    templateColumns: 'repeat(12, 1fr)',
    gap: 7,
    pb: '20px',
    children: [
      {
        key: 'shipment-detail-fields-label',
        text: 'Shipment Details',
        renderComponent: renderText,
        color: 'accent.one.default',
        fontWeight: 'bold',
        fontSize: '15px',
        pt: '20px',
        textAlign: 'left',
      },
      {
        label: (
          <>
            BOL#&nbsp;
            <Tooltip
              content="The Bill of Lading (BOL) is a legal document issued by a carrier to acknowledge receipt of cargo."
              positioning={{ placement: 'right-end' }}
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
            :
          </>
        ),
        name: 'bolNumber',
        fieldType: 'input',
        colSpan: 2,
      },
      {
        label: (
          <>
            Container #&nbsp;
            <Tooltip content="Specifies the unique container number." positioning={{ placement: 'right-end' }}>
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
            :
          </>
        ),
        name: 'containerNumber',
        fieldType: 'input',
        colSpan: 2,
      },
      {
        label: (
          <>
            Booking Ref #&nbsp;
            <Tooltip
              content="The Booking Reference Number is a unique identifier provided by the shipping line to track."
              positioning={{ placement: 'right-end' }}
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
            :
          </>
        ),
        name: 'bookingReferenceNumber',
        fieldType: 'input',
        colSpan: 2,
      },
      {
        label: (
          <>
            Port Mark&nbsp;
            <Tooltip
              content="Selects the port mark associated with the rejected cartons from the dropdown list"
              positioning={{ placement: 'right-end' }}
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </>
        ),
        name: 'portMark',
        fieldType: 'input',
        colSpan: 2,
      },
      {
        label: (
          <>
            Port of Loading&nbsp;
            <Tooltip
              content="Identifies where the goods will be loaded for coordinating shipment details."
              positioning={{ placement: 'right-end' }}
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </>
        ),
        name: 'loadPort',
        isMulti: true,
        fieldType: 'select',
        optionsKey: 'formattedPort',
        colSpan: 2,
      },
    ],
  },
  {
    renderComponent: renderGrid,
    templateColumns: 'repeat(12, 1fr)',
    gap: 7,
    pb: '20px',
    mb: '20px',
    children: [
      {
        text: '',
        renderComponent: renderText,
        color: 'secondary.900',
        fontWeight: 'bold',
        fontSize: '15px',
        pt: '20px',
        textAlign: 'left',
      },
      {
        label: (
          <>
            ETD From&nbsp;
            <Tooltip content="Start date for the estimated time of departure." positioning={{ placement: 'right-end' }}>
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </>
        ),
        name: 'etdDateStart',
        fieldType: 'date',
        dateFormat: 'YYYY-MM-DD',
        colSpan: 2,
      },
      {
        label: (
          <>
            ETD To&nbsp;
            <Tooltip content="End date for the estimated time of departure." positioning={{ placement: 'right-end' }}>
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </>
        ),
        name: 'etdDateEnd',
        fieldType: 'date',
        dateFormat: 'YYYY-MM-DD',
        colSpan: 2,
      },
      {
        label: (
          <>
            Order Shipment Type&nbsp;
            <Tooltip content="Select the type of shipment for the order." positioning={{ placement: 'right-end' }}>
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </>
        ),
        name: 'orderShipmentTypes',
        fieldType: 'select',
        optionsKey: 'orderShipmentType',
        isMulti: true,
        colSpan: 2,
      },
      {
        label: (
          <>
            Missing Doc Type&nbsp;
            <Tooltip
              content="Specify the type of document that is missing for the order."
              positioning={{ placement: 'right-end' }}
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </>
        ),
        name: 'missingDocType',
        fieldType: 'select',
        optionsKey: 'missingDocType',
        colSpan: 2,
      },
      {
        label: (
          <>
            Port of Destination&nbsp;
            <Tooltip
              content="Specifies the destination port where the goods will be unloaded."
              positioning={{ placement: 'right-end' }}
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </>
        ),
        name: 'destPort',
        fieldType: 'select',
        isMulti: true,
        optionsKey: 'formattedPort',
        colSpan: 2,
      },
    ],
  },
  {
    renderComponent: renderGrid,
    templateColumns: 'repeat(12, 1fr)',
    gap: 7,
    pb: '20px',
    mb: '20px',
    children: [
      {
        text: '',
        renderComponent: renderText,
        color: 'secondary.900',
        fontWeight: 'bold',
        fontSize: '15px',
        pt: '20px',
        textAlign: 'left',
      },
      {
        label: (
          <>
            ETA From&nbsp;
            <Tooltip content="Start date for the estimated time of arrival." positioning={{ placement: 'right-end' }}>
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </>
        ),
        name: 'etaDateStart',
        fieldType: 'date',
        dateFormat: 'YYYY-MM-DD',
        colSpan: 2,
      },
      {
        label: (
          <>
            ETA To&nbsp;
            <Tooltip content="End date for the estimated time of arrival." positioning={{ placement: 'right-end' }}>
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </>
        ),
        name: 'etaDateEnd',
        fieldType: 'date',
        dateFormat: 'YYYY-MM-DD',
        colSpan: 2,
      },
      {
        label: (
          <>
            Coldstore&nbsp;
            <Tooltip
              content="Select the required cold storage facility from the dropdown"
              positioning={{ placement: 'right-end' }}
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </>
        ),
        name: 'coldstore',
        fieldType: 'select',
        optionsKey: 'formattedColdstore',
        isMulti: true,
        colSpan: 2,
      },
      {
        label: (
          <>
            Vessel Name&nbsp;
            <Tooltip
              content="Provides the name of the vessel transporting the goods."
              positioning={{ placement: 'right-end' }}
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
            :
          </>
        ),
        name: 'vesselName',
        fieldType: 'input',
        colSpan: 2,
      },
      {
        label: (
          <>
            Voyage Number&nbsp;
            <Tooltip
              content="Provides the voyage number assigned to the shipment."
              positioning={{ placement: 'right-end' }}
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
            :
          </>
        ),
        name: 'voyageNumber',
        fieldType: 'input',
        colSpan: 2,
      },
    ],
  },
];

export const formConfig = [
  {
    key: 'bulk-update-form-card',
    renderComponent: renderCard,
    pb: '20px',
    px: '35px',
    mx: '0px',
    minWidth: '1100px',
    backgroundColor: '#FAFAFA',
    children: [
      quickFilters,
      poFields,
      dateFields,
      supplierOrCustomerFields,
      {
        key: 'advanced-search-accordion',
        renderComponent: renderAccordion,
        children: [{ headerText: 'Advanced Search', children: [orderDetailFields, ...shipmentDetailFields] }],
      },
      {
        key: 'bulk-update-button-container',
        display: 'flex',
        justifyContent: 'flex-end',
        height: '30px',
        children: [
          {
            renderComponent: renderButton,
            text: 'Clear Filters',
            variant: 'borderless',
            key: 'clear-filters-button',
            colorScheme: 'actionSecondary',
            width: '119px',
            height: '43px',
            marginRight: '13px',
            fontSize: '16px',
            onClick: (e, context) => {
              const { resetForm } = context;
              resetForm();
            },
          },
          {
            renderComponent: renderButton,
            text: 'Search POs',
            key: 'submit-search',
            colorScheme: 'actionSecondary',
            width: '166px',
            height: '43px',
            fontSize: '16px',
            type: 'submit',
          },
        ],
      },
    ],
  },
];
