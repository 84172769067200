import React, { useEffect } from 'react';
import { Text, Card, Grid, GridItem, Stack, Box } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { fetchTreasuryData, fetchTreasuryAnnotations } from '../../../slices/treasury/treasuryReducers';
import { setActiveMarket } from '../../../slices/markets/marketReducers';
import {
  selectTreasuryError,
  selectTreasuryFetchParameters,
  selectTreasuryLoading,
  selectTreasuryTimeScale,
} from '../../../slices/treasury/selectors/selectTreasuryBasicData';
import TreasuryFilters from './TreasuryFilters';
import RiskManagement from './RiskManagement';
import PerformanceAndForecast from './PerformanceAndForecast';
import CostSavingsAndExpectedVolume from './CostSavingsAndExpectedVolume';
import LoadingOverlay from '../../../shared/LoadingOverlay/LoadingOverlay';
import TabBar from '../../../shared/TabBar';
import { MARKETS } from '../../../config/markets';
import Constants from '../../../Constants';
import IUnleashClassFlagProvider from '../../../components/unleash/UnleashClassFlagProvider';
import { Alert } from '@/components/ui/alert';

// This container is for the treasury page as a whole and manages data
const Treasury = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectTreasuryError);
  const loading = useSelector(selectTreasuryLoading);
  const timeScale = useSelector(selectTreasuryTimeScale);
  const { market: marketParam } = useParams();

  // Parameters for data fetch
  const dataFetchParameters = useSelector(selectTreasuryFetchParameters);
  useEffect(() => {
    dispatch(fetchTreasuryData(dataFetchParameters));
  }, [dispatch, dataFetchParameters]);

  useEffect(() => {
    dispatch(fetchTreasuryAnnotations());
  }, [dispatch, timeScale]);

  useEffect(() => {
    if (marketParam === MARKETS.Australia.url) {
      dispatch(setActiveMarket(MARKETS.Australia.key));
    }
    if (marketParam === MARKETS.USA.url) {
      dispatch(setActiveMarket(MARKETS.USA.key));
    }
  }, [marketParam, setActiveMarket]);

  if (error && !loading) {
    return (
      <div>
        <Alert title="Error" status="error">
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <div className="analytic-dashboard-container" style={{ position: 'relative' }}>
      <TabBar />
      <Stack>
        <Box>
          <Grid gap="16px" templateColumns="repeat(24, 1fr)">
            <GridItem colSpan={24}>
              <TreasuryFilters />
            </GridItem>
            <GridItem colSpan={24}>
              <Card.Root>
                <Card.Body>
                  <Text textStyle="2xl">Risk Management Strategy</Text>
                  <RiskManagement timeScale={timeScale} />
                </Card.Body>
              </Card.Root>
            </GridItem>
            <GridItem colSpan={24}>
              <Card.Root>
                <Card.Body>
                  <Text textStyle="2xl">Performance and Forecast</Text>
                  <PerformanceAndForecast />
                  <CostSavingsAndExpectedVolume />
                </Card.Body>
              </Card.Root>
            </GridItem>
          </Grid>
        </Box>
      </Stack>
      <LoadingOverlay loading={loading} />
    </div>
  );
};

const TreasuryUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.TREASURY_DASHBOARD}
        show={<Treasury {...props} />}
        hide={<h1>Feature Not Enabled</h1>}
      />
    </>
  );
};

export default TreasuryUnleashController;
