import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import {
  breakdownPackerPlantPropTypes,
  dataColorsPropTypes,
  highlightedPackerPlantsPropTypes,
  packerModePropTypes,
  selectedCategoriesPropTypes,
  selectedMarketsPropTypes,
  selectedSubcategoriesPropTypes,
  selectedYearsPropTypes,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsPropTypes';

import BreakdownChart from '../../../../components/analyticDashboard/qualityDetails/ncrs/BreakdownChart/BreakdownChart';
import ClaimDollarsChart from '../../../../components/analyticDashboard/qualityDetails/ncrs/ClaimDollarsChart/ClaimDollarsChart';
import IncidentsChart from '../../../../components/analyticDashboard/qualityDetails/ncrs/IncidentsChart/IncidentsChart';
import VolumeChart from '../../../../components/analyticDashboard/qualityDetails/ncrs/VolumeChart/VolumeChart';
import yearOnYear from '../../../../modules/data/year-on-year';
import {
  BREAKDOWN_OPTIONS,
  INCIDENTS_OPTIONS,
  PACKER_MODE,
  TIME_RANGE_OPTIONS,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsConfig';
import selectAvailablePackerPlants from '../../../../slices/quality/selectors/selectAvailablePackerPlants';
import {
  selectQualityNCRsActivePackerPlants,
  selectQualityNCRsBreakdownMode,
  selectQualityNCRsClaimDollars,
  selectQualityNCRsFrequency,
  selectQualityNCRsIncidents,
  selectQualityNCRsTimeRange,
  selectQualityNCRsVolume,
} from '../../../../slices/quality-details-ncrs/selectors/selectBasicDetailsNcrsData';
import selectQualityNCRsDataByCategory, {
  selectQualityNCRsPackerDataByCategory,
} from '../../../../slices/quality-details-ncrs/selectors/selectQualityNCRsDataByCategory';
import selectQualityNCRsDataByMarket from '../../../../slices/quality-details-ncrs/selectors/selectQualityNCRsDataByMarket';
import selectQualityNCRsDataBySubCategory, {
  selectQualityNCRsPackerDataBySubCategory,
} from '../../../../slices/quality-details-ncrs/selectors/selectQualityNCRsDataBySubCategory';
import selectQualityNCRsDataFiltered, {
  selectQualityNCRsPackerDataFiltered,
} from '../../../../slices/quality-details-ncrs/selectors/selectQualityNCRsDataFiltered';
import { FREQUENCY } from '../../../../slices/quality/qualityConfig';

export default function QualityDetailsNCRsCharts(props) {
  const {
    dataColors,
    selectedYears,
    selectedCategories,
    selectedSubcategories,
    selectedMarkets,
    packerMode,
    breakdownPackerPlant,
    highlightedPackerPlants,
  } = props;
  const incidentsMode = useSelector(selectQualityNCRsIncidents);
  const volumeMode = useSelector(selectQualityNCRsVolume);
  const claimDollarsMode = useSelector(selectQualityNCRsClaimDollars);
  const breakdownMode = useSelector(selectQualityNCRsBreakdownMode);
  const activePackerPlants = useSelector(selectQualityNCRsActivePackerPlants);
  const timeRange = useSelector(selectQualityNCRsTimeRange);
  const data = useSelector(selectQualityNCRsDataFiltered);
  const dataByCategory = useSelector(selectQualityNCRsDataByCategory);
  const dataBySubCategory = useSelector(selectQualityNCRsDataBySubCategory);
  const dataByMarket = useSelector(selectQualityNCRsDataByMarket);

  const charts = [
    incidentsMode && IncidentsChart,
    (breakdownMode || packerMode === PACKER_MODE.BOTTOM_10) && BreakdownChart,
    volumeMode && VolumeChart,
    claimDollarsMode && ClaimDollarsChart,
  ].filter(x => !!x);

  const columns = useMemo(() => {
    if (timeRange === TIME_RANGE_OPTIONS.YOY) {
      return selectedYears;
    }
    if ((!incidentsMode || incidentsMode === INCIDENTS_OPTIONS.TOTAL) && timeRange === TIME_RANGE_OPTIONS.HISTORICAL) {
      if (activePackerPlants.filter(x => !!x).length > 0) {
        return activePackerPlants.filter(x => !!x).map(x => x.name);
      }
      return ['value'];
    }
    if (incidentsMode === INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN) {
      return ['value'];
    }
    if (incidentsMode === INCIDENTS_OPTIONS.BY_CATEGORY) {
      if (activePackerPlants.filter(x => !!x).length >= 2) {
        return activePackerPlants.filter(x => !!x).map(x => x.name);
      }
      return selectedCategories;
    }
    if (incidentsMode === INCIDENTS_OPTIONS.BY_SUBCATEGORY) {
      if (activePackerPlants.filter(x => !!x).length >= 2) {
        return activePackerPlants.filter(x => !!x).map(x => x.name);
      }
      return selectedSubcategories;
    }
    if (incidentsMode === INCIDENTS_OPTIONS.BY_MARKET) {
      return selectedMarkets;
    }
    return [];
  }, [
    incidentsMode,
    timeRange,
    activePackerPlants,
    selectedYears,
    selectedCategories,
    selectedSubcategories,
    selectedMarkets,
  ]);

  const colors = useMemo(() => {
    if (
      ((!incidentsMode || incidentsMode === INCIDENTS_OPTIONS.TOTAL) &&
        timeRange === TIME_RANGE_OPTIONS.HISTORICAL &&
        activePackerPlants.filter(x => !!x).length < 1) ||
      incidentsMode === INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN
    ) {
      return { value: '#4E5984' };
    }
    return dataColors;
  }, [dataColors, incidentsMode, timeRange, activePackerPlants]);

  const commonProps = {
    columns,
    colors,
    breakdownColors: dataColors,
    activePackerPlants,
    data,
    dataByCategory,
    dataBySubCategory,
    dataByMarket,
    selectedCategories,
    selectedSubcategories,
    selectedMarkets,
    highlightedPackerPlants,
    breakdownPackerPlant,
    packerMode,
  };

  return (
    <div>
      {charts.length > 1 &&
        charts.map(Chart => (
          <div key={Chart}>
            <Chart {...commonProps} />
          </div>
        ))}
      {/* hack to rerender the charts so it takes up the correct height */}
      {charts.length === 1 &&
        charts.map((Chart, i) => (
          <div key={`chart-${i}`}>
            <Chart {...commonProps} isLarge />
          </div>
        ))}
    </div>
  );
}

QualityDetailsNCRsCharts.propTypes = {
  breakdownPackerPlant: breakdownPackerPlantPropTypes,
  dataColors: dataColorsPropTypes,
  highlightedPackerPlants: highlightedPackerPlantsPropTypes,
  packerMode: packerModePropTypes,
  selectedCategories: selectedCategoriesPropTypes,
  selectedMarkets: selectedMarketsPropTypes,
  selectedSubcategories: selectedSubcategoriesPropTypes,
  selectedYears: selectedYearsPropTypes,
};

export function useChartData(fieldName, selectedCategories, selectedSubcategories) {
  const [chartData, setChartData] = useState([]);

  const incidentsMode = useSelector(selectQualityNCRsIncidents);
  const activePackerPlants = useSelector(selectQualityNCRsActivePackerPlants);
  const timeRange = useSelector(selectQualityNCRsTimeRange);
  const frequency = useSelector(selectQualityNCRsFrequency);
  const data = useSelector(selectQualityNCRsDataFiltered);
  const dataByCategory = useSelector(selectQualityNCRsDataByCategory);
  const dataBySubCategory = useSelector(selectQualityNCRsDataBySubCategory);
  const dataByMarket = useSelector(selectQualityNCRsDataByMarket);

  // FIME, loads of repeated code
  useEffect(() => {
    if (data && dataByCategory && dataBySubCategory && dataByMarket) {
      if (timeRange === TIME_RANGE_OPTIONS.YOY) {
        if (activePackerPlants.filter(x => !!x).length >= 1) {
          setChartData(
            yearOnYear(
              data.map(x => {
                const packerPlants = {};
                x.byPackerPlant.forEach(p => {
                  const packerPlant = activePackerPlants.find(ap => ap.id === p.packerPlantId);
                  if (packerPlant) {
                    packerPlants[packerPlant.name] = p[fieldName];
                  }
                });
                return {
                  interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
                  value: Object.values(packerPlants)[0] || 0,
                };
              })
            )
          );
        } else {
          setChartData(
            yearOnYear(
              data.map(x => ({
                interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
                value: x[fieldName] || 0,
              }))
            )
          );
        }
      } else if (
        (!incidentsMode || incidentsMode === INCIDENTS_OPTIONS.TOTAL) &&
        timeRange === TIME_RANGE_OPTIONS.HISTORICAL
      ) {
        if (activePackerPlants.filter(x => !!x).length >= 1) {
          setChartData(
            data.map(x => {
              const packerPlants = {};
              x.byPackerPlant.forEach(p => {
                const packerPlant = activePackerPlants.find(ap => ap.id === p.packerPlantId);
                if (packerPlant) {
                  packerPlants[packerPlant.name] = p[fieldName];
                }
              });
              return {
                interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
                ...packerPlants,
              };
            })
          );
        } else {
          setChartData(
            data.map(x => ({
              interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
              value: x[fieldName] || 0,
            }))
          );
        }
      } else if (incidentsMode === INCIDENTS_OPTIONS.TOTAL_WITH_BREAKDOWN) {
        setChartData(
          data.map(x => ({
            interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
            value: x[fieldName] || 0,
          }))
        );
      } else if (incidentsMode === INCIDENTS_OPTIONS.BY_CATEGORY) {
        if (activePackerPlants.filter(x => !!x).length >= 2 && selectedCategories.length) {
          setChartData(
            dataByCategory.map(x => {
              const selectedCategory = x.byCategory.find(c => c.category === selectedCategories[0]);
              if (!selectedCategory) return;
              const packerPlants = {};
              selectedCategory.byPackerPlant.forEach(p => {
                const packerPlant = activePackerPlants.find(ap => ap.id === p.packerPlantId);
                if (packerPlant) {
                  packerPlants[packerPlant.name] = p[fieldName];
                }
              });
              // eslint-disable-next-line consistent-return
              return {
                interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
                ...packerPlants,
              };
            })
          );
        } else {
          setChartData(
            dataByCategory.map(x => {
              const categories = {};
              x.byCategory.forEach(c => {
                categories[c.category] = c[fieldName];
              });
              return {
                interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
                ...categories,
              };
            })
          );
        }
      } else if (incidentsMode === INCIDENTS_OPTIONS.BY_SUBCATEGORY) {
        if (activePackerPlants.filter(x => !!x).length >= 2 && selectedSubcategories.length > 0) {
          setChartData(
            dataBySubCategory.map(x => {
              const selectedSubCategory = x.bySubCategory.find(c => c.subCategory === selectedSubcategories[0]);
              const packerPlants = {};
              selectedSubCategory.byPackerPlant.forEach(p => {
                const packerPlant = activePackerPlants.find(ap => ap.id === p.packerPlantId);
                if (packerPlant) {
                  packerPlants[packerPlant.name] = p[fieldName];
                }
              });
              return {
                interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
                ...packerPlants,
              };
            })
          );
        } else {
          setChartData(
            dataBySubCategory.map(x => {
              const subCategories = {};
              x.bySubCategory.forEach(s => {
                subCategories[s.subCategory] = s[fieldName];
              });
              return {
                interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
                ...subCategories,
              };
            })
          );
        }
      } else if (incidentsMode === INCIDENTS_OPTIONS.BY_MARKET) {
        setChartData(
          dataByMarket.map(x => {
            const markets = {};
            x.byMarket.forEach(m => {
              markets[m.market] = m[fieldName];
            });
            return {
              interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
              ...markets,
            };
          })
        );
      }
    }
  }, [
    fieldName,
    data,
    dataByCategory,
    dataBySubCategory,
    dataByMarket,
    incidentsMode,
    timeRange,
    frequency,
    activePackerPlants,
    selectedCategories,
    selectedSubcategories,
  ]);

  return chartData;
}

export function useBreakdownData(packerMode, selectedCategories, selectedSubcategories) {
  const breakdownMode = useSelector(selectQualityNCRsBreakdownMode);
  const incidentsMode = useSelector(selectQualityNCRsIncidents);
  const data = useSelector(selectQualityNCRsDataFiltered);
  const packerData = useSelector(selectQualityNCRsPackerDataFiltered);
  const dataByCategory = useSelector(selectQualityNCRsDataByCategory);
  const packerDataByCategory = useSelector(selectQualityNCRsPackerDataByCategory);
  const packerDataBySubCategory = useSelector(selectQualityNCRsPackerDataBySubCategory);
  const dataByMarket = useSelector(selectQualityNCRsDataByMarket);
  const availablePackerPlants = useSelector(selectAvailablePackerPlants);
  const activePackerPlants = useSelector(selectQualityNCRsActivePackerPlants);

  const [breakdownData, setBreakdownData] = useState([]);

  const isBottomTenTotal = !!activePackerPlants[0]?.bottomTenPackerPlants && packerMode === PACKER_MODE.BOTTOM_10_TOTAL;

  useEffect(() => {
    if (breakdownMode === BREAKDOWN_OPTIONS.CATEGORY && dataByCategory) {
      const d = dataByCategory.map(x => {
        const total = x.byCategory.reduce(
          (a, b) => a + b[isBottomTenTotal ? 'incidentsCountBottomTen' : 'incidentsCount'],
          0
        );
        return {
          interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
          rankingBreakdown: x.byCategory
            .map(c => ({
              orderType: c.category,
              volumeRatio: (isBottomTenTotal ? c.incidentsCountBottomTen : c.incidentsCount) / total,
            }))
            .sort((a, b) => b.volumeRatio - a.volumeRatio),
        };
      });
      setBreakdownData(d);
    } else if (breakdownMode === BREAKDOWN_OPTIONS.MARKET && dataByMarket) {
      const d = dataByMarket.map(x => {
        const total = x.byMarket.reduce(
          (a, b) => a + b[isBottomTenTotal ? 'incidentsCountBottomTen' : 'incidentsCount'],
          0
        );
        return {
          interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
          rankingBreakdown: x.byMarket
            .map(c => ({
              orderType: c.market,
              volumeRatio: (isBottomTenTotal ? c.incidentsCountBottomTen : c.incidentsCount) / total,
            }))
            .sort((a, b) => b.volumeRatio - a.volumeRatio),
        };
      });
      setBreakdownData(d);
    } else if (breakdownMode === BREAKDOWN_OPTIONS.PACKER_PLANT && data) {
      const d = data.map(x => {
        const total = x.incidentsCount;
        return {
          interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
          rankingBreakdown: x.byPackerPlant
            .map(p => {
              const pac = availablePackerPlants.find(
                ap => ap.id === p.packerPlantId && !Array.isArray(ap.includesPackerPlants)
              );
              return {
                orderType: pac?.name,
                volumeRatio: p.incidentsCount / total,
              };
            })
            .filter(o => o.orderType !== undefined)
            .sort((a, b) => b.volumeRatio - a.volumeRatio)
            .slice(0, 10),
        };
      });
      setBreakdownData(d);
    }
    // TODO check if this is valid option on UI
    else if (incidentsMode === INCIDENTS_OPTIONS.TOTAL && packerMode === PACKER_MODE.BOTTOM_10 && packerData) {
      const d = packerData.map(x => {
        const total = x.incidentsCount;
        return {
          interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
          rankingBreakdown: x.byPackerPlant
            .map(p => {
              const pac = availablePackerPlants.find(ap => ap.id === p.packerPlantId);
              return {
                orderType: pac?.name,
                volumeRatio: p.incidentsCount / total,
              };
            })
            .sort((a, b) => b.volumeRatio - a.volumeRatio),
        };
      });
      setBreakdownData(d);
    }
    // TODO Check if this is valid case
    else if (
      incidentsMode === INCIDENTS_OPTIONS.BY_CATEGORY &&
      packerMode === PACKER_MODE.BOTTOM_10 &&
      packerData &&
      !!selectedCategories[0]
    ) {
      const d = packerDataByCategory.map(x => {
        const selCat = x.byCategory.find(c => c.category === selectedCategories[0]);
        const total = selCat?.byPackerPlant.reduce((a, b) => a + b.incidentsCount, 0);
        return {
          interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
          rankingBreakdown: selCat?.byPackerPlant
            .map(p => {
              const pac = availablePackerPlants.find(ap => ap.id === p.packerPlantId);
              return {
                orderType: pac?.name,
                volumeRatio: p.incidentsCount / total,
              };
            })
            .sort((a, b) => b.volumeRatio - a.volumeRatio),
        };
      });
      setBreakdownData(d);
    }
    // TODO check if this is valid option on UI
    else if (
      incidentsMode === INCIDENTS_OPTIONS.BY_SUBCATEGORY &&
      packerMode === PACKER_MODE.BOTTOM_10 &&
      packerData &&
      !!selectedSubcategories[0]
    ) {
      const d = packerDataBySubCategory.map(x => {
        const selSubCat = x.bySubCategory.find(c => c.subCategory === selectedSubcategories[0]);
        const total = selSubCat?.byPackerPlant.reduce((a, b) => a + b.incidentsCount, 0);
        return {
          interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
          rankingBreakdown: selSubCat?.byPackerPlant
            .map(p => {
              const pac = availablePackerPlants.find(ap => ap.id === p.packerPlantId);
              return {
                orderType: pac?.name,
                volumeRatio: p.incidentsCount / total,
              };
            })
            .sort((a, b) => b.volumeRatio - a.volumeRatio),
        };
      });
      setBreakdownData(d);
    }
  }, [
    breakdownMode,
    packerMode,
    selectedCategories,
    selectedSubcategories,
    data,
    packerData,
    dataByCategory,
    dataByMarket,
  ]);

  return breakdownData;
}
