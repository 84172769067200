import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { upperFirst } from 'lodash';
import BreakdownChart from '../../../../components/analyticDashboard/qualityDetails/claims/BreakdownChart/BreakdownChart';
import yearOnYear from '../../../../modules/data/year-on-year';
import { format } from 'date-fns';
import {
  selectQualityClaimsAmount,
  selectQualityClaimsCount,
  selectQualityClaimsFilterBy,
  selectQualityClaimsFilteredByPackerPlant,
  selectQualityClaimsFrequency,
  selectQualityClaimsGroupBy,
  selectQualityClaimsStatus,
  selectQualityClaimsTimeRange,
} from '../../../../slices/quality-details-claims/selectors/selectBasicDetailsClaimsData';
import {
  FILTER_BY_OPTIONS,
  GROUP_BY_OPTIONS,
  STATUS_OPTIONS,
  TIME_RANGE_OPTIONS,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';
import CountChart from '../../../../components/analyticDashboard/qualityDetails/claims/CountChart/CountChart';
import AmountChart from '../../../../components/analyticDashboard/qualityDetails/claims/AmountChart/AmountChart';
import selectQualityClaimsDataFiltered from '../../../../slices/quality-details-claims/selectors/selectQualityClaimsDataFiltered';
import { FREQUENCY } from '../../../../slices/quality/qualityConfig';
import selectAvailablePackerPlants from '../../../../slices/quality/selectors/selectAvailablePackerPlants';

export default function QualityDetailsClaimsCharts(props) {
  const {
    dataColors,
    selectedStatuses,
    selectedYears,
    selectedCategories,
    selectedClaimAmounts,
    selectedDaysToResolve,
    highlightedPackerPlants,
  } = props;
  const statusMode = useSelector(selectQualityClaimsStatus);
  const countMode = useSelector(selectQualityClaimsCount);
  const amountMode = useSelector(selectQualityClaimsAmount);
  const groupByMode = useSelector(selectQualityClaimsGroupBy);
  const timeRange = useSelector(selectQualityClaimsTimeRange);

  const charts = [
    countMode && CountChart,
    amountMode && AmountChart,
    groupByMode === GROUP_BY_OPTIONS.PACKER_PLANT && (countMode || amountMode) && BreakdownChart,
  ].filter(x => !!x);

  const columns = useMemo(() => {
    if (timeRange === TIME_RANGE_OPTIONS.YOY) {
      return selectedYears;
    }
    if (timeRange === TIME_RANGE_OPTIONS.HISTORICAL) {
      if (
        statusMode === STATUS_OPTIONS.SUBMITTED_VS_FINALISED ||
        statusMode === STATUS_OPTIONS.SUBMITTED_VS_REJECTED ||
        statusMode === STATUS_OPTIONS.FINALISED_VS_REJECTED
      ) {
        return selectedStatuses;
      }
      if (groupByMode === GROUP_BY_OPTIONS.CATEGORY) {
        return selectedCategories;
      }
      if (groupByMode === GROUP_BY_OPTIONS.CLAIM_AMOUNT) {
        return selectedClaimAmounts;
      }
      if (groupByMode === GROUP_BY_OPTIONS.DAYS_TO_RESOLVE) {
        return selectedDaysToResolve;
      }
      return ['value'];
    }
    return [];
  }, [
    timeRange,
    selectedYears,
    selectedStatuses,
    statusMode,
    selectedCategories,
    selectedClaimAmounts,
    selectedDaysToResolve,
  ]);

  const colors = useMemo(() => {
    if (
      timeRange === TIME_RANGE_OPTIONS.HISTORICAL &&
      !groupByMode &&
      !(
        statusMode === STATUS_OPTIONS.SUBMITTED_VS_FINALISED ||
        statusMode === STATUS_OPTIONS.SUBMITTED_VS_REJECTED ||
        statusMode === STATUS_OPTIONS.FINALISED_VS_REJECTED
      )
    ) {
      return { value: '#4E5984' };
    }
    return dataColors;
  }, [dataColors, timeRange]);

  const commonProps = {
    columns,
    colors,
    selectedCategories,
    selectedClaimAmounts,
    selectedDaysToResolve,
    breakdownColors: dataColors,
    highlightedPackerPlants,
  };

  return (
    <div>
      {charts.length > 1 &&
        charts.map((Chart, i) => (
          <div key={`chart-${i}`}>
            <Chart {...commonProps} />
          </div>
        ))}
      {/* hack to rerender the charts so it takes up the correct height */}
      {charts.length === 1 &&
        charts.map((Chart, i) => (
          <div key={`chart-${i}`}>
            <Chart {...commonProps} isLarge />
          </div>
        ))}
    </div>
  );
}

function useFilteredData(selectedCategories, selectedClaimAmounts, selectedDaysToResolve) {
  const [data, setData] = useState([]);

  const dataFiltered = useSelector(selectQualityClaimsDataFiltered);
  const filterBy = useSelector(selectQualityClaimsFilterBy);
  const filteredByPackerPlant = useSelector(selectQualityClaimsFilteredByPackerPlant);

  useEffect(() => {
    if (filterBy === FILTER_BY_OPTIONS.PACKER_PLANT && filteredByPackerPlant) {
      if (filteredByPackerPlant.id === 'bottom_10') {
        const { bottomTenPackerPlants } = filteredByPackerPlant;
        const bottomTenPackerPlantsIds = bottomTenPackerPlants.map(pp => pp.id);
        setData(
          dataFiltered.map(intervalData => {
            let dataByBottomTenPackerPlants = [];
            const stat = intervalData.byStatus.map(statusData => {
              dataByBottomTenPackerPlants = dataByBottomTenPackerPlants.concat(
                statusData.byPackerPlant.filter(pp => bottomTenPackerPlantsIds.includes(pp.packerPlantId))
              );
              const getClaimDataByKey = key => {
                const dataMap = new Map();
                const dataArrayByKey = dataByBottomTenPackerPlants.map(x => x[`by${upperFirst(key)}`]).flat();
                dataArrayByKey.forEach(d => {
                  const dataByKey = dataMap.get(d[key]);
                  if (dataByKey) {
                    dataMap.set(d[key], {
                      amount: dataByKey.amount + d.amount,
                      count: dataByKey.count + d.count,
                    });
                  } else {
                    dataMap.set(d[key], { amount: d.amount, count: d.count });
                  }
                });
                const result = Array.from(dataMap).map(([dataMapKey, value]) => ({
                  [key]: dataMapKey,
                  amount: value.amount,
                  count: value.count,
                }));
                return result;
              };

              const byPackerPlant = {
                packerPlantId: 'bottom_10',
                amount: dataByBottomTenPackerPlants.map(x => x.amount).reduce((a, b) => a + b, 0),
                count: dataByBottomTenPackerPlants.map(x => x.count).reduce((a, b) => a + b, 0),
                byCategory: getClaimDataByKey('category'),
                byClaimAmount: getClaimDataByKey('claimAmount'),
                byDaysToResolve: getClaimDataByKey('daysToResolve'),
              };

              return {
                status: statusData.status,
                ...byPackerPlant,
              };
            });
            return {
              date: intervalData.date,
              byStatus: stat,
            };
          })
        );
      } else {
        setData(
          dataFiltered.map(intervalData => {
            const stat = intervalData.byStatus.map(statusData => {
              const byPackerPlant = statusData.byPackerPlant.find(
                packerPlantData => packerPlantData.packerPlantId === filteredByPackerPlant.id
              );
              return {
                status: statusData.status,
                ...byPackerPlant,
              };
            });
            return {
              date: intervalData.date,
              byStatus: stat,
            };
          })
        );
      }
    } else if (filterBy === FILTER_BY_OPTIONS.CATEGORY && selectedCategories && selectedCategories[0]) {
      setData(
        dataFiltered.map(intervalData => {
          const stat = intervalData.byStatus.map(statusData => {
            const byCategory = statusData.byCategory.find(
              categoryData => categoryData.category === selectedCategories[0]
            );
            return {
              status: statusData.status,
              ...byCategory,
            };
          });
          return {
            date: intervalData.date,
            byStatus: stat,
          };
        })
      );
    } else if (filterBy === FILTER_BY_OPTIONS.CLAIM_AMOUNT && selectedClaimAmounts && selectedClaimAmounts[0]) {
      setData(
        dataFiltered.map(intervalData => {
          const stat = intervalData.byStatus.map(statusData => {
            const byClaimAmount = statusData.byClaimAmount.find(
              claimAmountData => claimAmountData.claimAmount === selectedClaimAmounts[0]
            );
            return {
              status: statusData.status,
              ...byClaimAmount,
            };
          });
          return {
            date: intervalData.date,
            byStatus: stat,
          };
        })
      );
    } else if (filterBy === FILTER_BY_OPTIONS.DAYS_TO_RESOLVE && selectedDaysToResolve && selectedDaysToResolve[0]) {
      setData(
        dataFiltered.map(intervalData => {
          const stat = intervalData.byStatus.map(statusData => {
            const byDaysToResolve = statusData.byDaysToResolve.find(
              daysToResolveData => daysToResolveData.daysToResolve === selectedDaysToResolve[0]
            );
            return {
              status: statusData.status,
              ...byDaysToResolve,
            };
          });
          return {
            date: intervalData.date,
            byStatus: stat,
          };
        })
      );
    } else {
      setData(dataFiltered);
    }
  }, [dataFiltered, filterBy, filteredByPackerPlant, selectedCategories, selectedClaimAmounts, selectedDaysToResolve]);

  return data;
}

export function useChartData(fieldName, selectedCategories, selectedClaimAmounts, selectedDaysToResolve) {
  const [chartData, setChartData] = useState([]);

  const statusMode = useSelector(selectQualityClaimsStatus);
  const timeRange = useSelector(selectQualityClaimsTimeRange);
  const frequency = useSelector(selectQualityClaimsFrequency);
  const groupBy = useSelector(selectQualityClaimsGroupBy);
  const data = useFilteredData(selectedCategories, selectedClaimAmounts, selectedDaysToResolve);

  useEffect(() => {
    if (data) {
      if (timeRange === TIME_RANGE_OPTIONS.YOY) {
        setChartData(
          yearOnYear(
            data.map(x => ({
              interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
              value: x.byStatus[0] ? x.byStatus[0][fieldName] : 0,
            }))
          )
        );
      } else if (
        statusMode === STATUS_OPTIONS.SUBMITTED_VS_FINALISED ||
        statusMode === STATUS_OPTIONS.SUBMITTED_VS_REJECTED ||
        statusMode === STATUS_OPTIONS.FINALISED_VS_REJECTED
      ) {
        setChartData(
          data.map(x => {
            const firstStatus = x.byStatus[0]
              ? {
                  [x.byStatus[0].status]: x.byStatus[0][fieldName],
                }
              : {};
            const secondStatus = x.byStatus[1]
              ? {
                  [x.byStatus[1].status]: x.byStatus[1][fieldName],
                }
              : {};
            return {
              interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
              ...firstStatus,
              ...secondStatus,
            };
          })
        );
      } else if (groupBy === GROUP_BY_OPTIONS.CATEGORY) {
        setChartData(
          data.map(x => {
            const entities = {};
            x.byStatus[0] &&
              x.byStatus[0].byCategory &&
              x.byStatus[0].byCategory.forEach(c => {
                entities[c.category] = c[fieldName];
              });
            return {
              interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
              ...entities,
            };
          })
        );
      } else if (groupBy === GROUP_BY_OPTIONS.CLAIM_AMOUNT) {
        setChartData(
          data.map(x => {
            const entities = {};
            x.byStatus[0] &&
              x.byStatus[0].byClaimAmount &&
              x.byStatus[0].byClaimAmount.forEach(c => {
                entities[c.claimAmount] = c[fieldName];
              });
            return {
              interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
              ...entities,
            };
          })
        );
      } else if (groupBy === GROUP_BY_OPTIONS.DAYS_TO_RESOLVE) {
        setChartData(
          data.map(x => {
            const entities = {};
            x.byStatus[0] &&
              x.byStatus[0].byDaysToResolve &&
              x.byStatus[0].byDaysToResolve.forEach(c => {
                entities[c.daysToResolve] = c[fieldName];
              });
            return {
              interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
              ...entities,
            };
          })
        );
      } else {
        setChartData(
          data.map(x => ({
            interval: format(new Date(x.date).getTime(), frequency === FREQUENCY.MONTHLY ? 'MMM yyyy' : 'yyyy'),
            value: x.byStatus[0] ? x.byStatus[0][fieldName] : 0,
          }))
        );
      }
    }
  }, [fieldName, data, timeRange, groupBy, statusMode]);

  return chartData;
}

export function useBreakdownData(selectedCategories, selectedClaimAmounts, selectedDaysToResolve) {
  const groupBy = useSelector(selectQualityClaimsGroupBy);
  const availablePackerPlants = useSelector(selectAvailablePackerPlants);
  const countMode = useSelector(selectQualityClaimsCount);
  const amountMode = useSelector(selectQualityClaimsAmount);
  const data = useFilteredData(selectedCategories, selectedClaimAmounts, selectedDaysToResolve);

  const [breakdownData, setBreakdownData] = useState([]);

  const fieldName = useMemo(() => {
    if (!!countMode && !amountMode) {
      return 'count';
    }
    if (!countMode && !!amountMode) {
      return 'amount';
    }
  }, [countMode, amountMode]);

  useEffect(() => {
    if (groupBy === GROUP_BY_OPTIONS.PACKER_PLANT && data) {
      const d = data.map(x => {
        let total = x.byStatus[0] ? x.byStatus[0][fieldName] : 0;
        let rankingBreakdown = (x.byStatus[0]?.byPackerPlant || []).map(p => {
          const pac = availablePackerPlants.find(ap => ap.id === p.packerPlantId);
          return {
            orderType: pac?.name,
            volumeRatio: p[fieldName] && total > 0 ? p[fieldName] / total : 0,
          };
        });

        if (x.byStatus.length === 2) {
          const availPackerPlantsWithRatio = availablePackerPlants.map(availP => {
            const valFirst = (x.byStatus[0].byPackerPlant || []).find(pp => availP.id === pp.packerPlantId);
            const valSecond = (x.byStatus[1].byPackerPlant || []).find(pp => availP.id === pp.packerPlantId);
            const fieldValFirst = valFirst && valFirst[fieldName];
            const fieldValSecond = valSecond && valSecond[fieldName];
            const ratio = fieldValSecond && fieldValFirst && fieldValFirst > 0 ? fieldValSecond / fieldValFirst : 0;
            return {
              ...availP,
              ratio,
            };
          });
          total = availPackerPlantsWithRatio.reduce((a, b) => a + b.ratio, 0);

          rankingBreakdown = (x.byStatus[0].byPackerPlant || []).map(p => {
            const pac = availPackerPlantsWithRatio.find(ap => ap.id === p.packerPlantId);
            return {
              orderType: pac?.name,
              volumeRatio: pac?.ratio / total,
            };
          });
        }

        return {
          interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
          rankingBreakdown: rankingBreakdown.sort((a, b) => b.volumeRatio - a.volumeRatio),
        };
      });
      setBreakdownData(d);
    }
  }, [groupBy, data]);

  return breakdownData;
}
