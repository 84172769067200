import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Box, Grid, GridItem } from '@chakra-ui/react';

import { fetchMarketsData, fetchMarketsYtdData, setActiveMarket } from '../../../slices/markets/marketReducers';
import {
  selectMarketFetchParameters,
  selectMarketsDataRaw,
  selectMarketsError,
  selectMarketsLoading,
} from '../../../slices/markets/selectors/selectBasicMarketData';
import CostPerformance from './CostPerformance';
import MarketsFilters from './MarketsFilters';
import McMenu from './McMenu';
import SupplyChain from './SupplyChain';
import LoadingOverlay from '../../../shared/LoadingOverlay/LoadingOverlay';
import TabBar from '../../../shared/TabBar';
import { MARKETS } from '../../../config/markets';
import Constants from '../../../Constants';
import IUnleashClassFlagProvider from '../../../components/unleash/UnleashClassFlagProvider';
import { Alert } from '@/components/ui/alert';
// This container is for the Market page as a whole and manages data

const Markets = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectMarketsError);
  const loading = useSelector(selectMarketsLoading);
  const data = useSelector(selectMarketsDataRaw);
  const { market: marketParam } = useParams();

  // Parameters for data fetch
  const fetchParameters = useSelector(selectMarketFetchParameters);

  useEffect(() => {
    dispatch(fetchMarketsData(fetchParameters));
    dispatch(fetchMarketsYtdData(fetchParameters));
  }, [dispatch, fetchParameters]);

  useEffect(() => {
    if (marketParam === MARKETS.Australia.url) {
      dispatch(setActiveMarket(MARKETS.Australia.key));
    }
    if (marketParam === MARKETS.USA.url) {
      dispatch(setActiveMarket(MARKETS.USA.key));
    }
  }, [marketParam, setActiveMarket]);

  if (error && !loading) {
    return (
      <div>
        <Alert title="Error" status="error">
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <div className="analytic-dashboard-container" style={{ position: 'relative' }}>
      <TabBar />
      <Box backgroundColor="#f0f2f5">
        <Box padding={4} className="ant-layout-content">
          <Grid gap={4} templateColumns="repeat(24, 1fr)">
            <GridItem colSpan={24}>
              <MarketsFilters />
            </GridItem>
            <GridItem colSpan={12}>
              <Flex size="md" flexDirection="column">
                {data && <CostPerformance />}
                {data && <McMenu />}
              </Flex>
            </GridItem>
            <GridItem colSpan={12}>{data && <SupplyChain />}</GridItem>
            {/* <GridItem colSpan={24}>
              <Quality />
            </GridItem>
            <GridItem colSpan={24}>
            <ScaleForGood />
            </GridItem> */}
          </Grid>
        </Box>
      </Box>
      <LoadingOverlay loading={loading} />
    </div>
  );
};

const MarketDashboardUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.MARKET_DASHBOARD}
        show={<Markets {...props} />}
        hide={<h1>Feature Not Enabled</h1>}
      />
    </>
  );
};

export default MarketDashboardUnleashController;
