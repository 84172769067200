import React, { useEffect } from 'react';
import { Card, Grid, GridItem, Box, Stack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchMarketsData } from '../../../slices/markets/marketReducers';
import {
  selectMarketFetchParameters,
  selectMarketsError,
  selectMarketsLoading,
  selectMarketsCurrentPeriodDataRaw,
} from '../../../slices/markets/selectors/selectBasicMarketData';
import LoadingOverlay from '../../../shared/LoadingOverlay/LoadingOverlay';

import Details from '../../../components/analyticDashboard/supplyChainDetails/Details/Details';
import SupplyChainFilters from './SupplyChainFilters';
import DetailPageHeading from '../../../shared/DetailPageHeading/DetailPageHeading';
import TabBar from '../../../shared/TabBar';
import { Alert } from '@/components/ui/alert';

// This container is for the Market page as a whole and manages data

export default function SupplyChainDetails() {
  const dispatch = useDispatch();
  const error = useSelector(selectMarketsError);
  const loading = useSelector(selectMarketsLoading);
  const data = useSelector(selectMarketsCurrentPeriodDataRaw);

  // Parameters for data fetch
  const fetchParameters = useSelector(selectMarketFetchParameters);

  useEffect(() => {
    dispatch(fetchMarketsData(fetchParameters));
  }, [dispatch, fetchParameters]);

  if (error) {
    return (
      <div>
        <Alert title="Error" status="error" subTitle={error} />
      </div>
    );
  }

  return (
    <div style={{ position: 'relative' }}>
      <TabBar />
      <DetailPageHeading title="Supply Chain Details" />
      <Stack>
        <Box style={{ padding: 24 }}>
          <Grid gap="16px" templateColumns="repeat(24, 1fr)">
            <GridItem colSpan={24}>{data && <SupplyChainFilters />}</GridItem>
            <GridItem colSpan={24}>
              <Card.Root style={{ height: '100%' }}>
                <Card.Body>{data && <Details />}</Card.Body>
              </Card.Root>
            </GridItem>
          </Grid>
        </Box>
      </Stack>
      <LoadingOverlay loading={loading} />
    </div>
  );
}
