import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Card, Grid, GridItem, Box, Stack } from '@chakra-ui/react';

import { fetchTreasuryData, fetchTreasuryAnnotations } from '../../../slices/treasury/treasuryReducers';
import {
  selectTreasuryError,
  selectTreasuryFetchParameters,
  selectTreasuryLoading,
} from '../../../slices/treasury/selectors/selectTreasuryBasicData';
import TreasuryDetailsFilters from './TreasuryDetailsFilters';
import Charts from '../../../components/analyticDashboard/treasuryDetails/Charts/Charts';
import LoadingOverlay from '../../../shared/LoadingOverlay/LoadingOverlay';
import TabBar from '../../../shared/TabBar';
import { Alert } from '@/components/ui/alert';

// This container is for the treasury page as a whole and manages data
export default function TreasuryDetails() {
  const dispatch = useDispatch();
  const error = useSelector(selectTreasuryError);
  const loading = useSelector(selectTreasuryLoading);

  // Parameters for data fetch
  const dataFetchParameters = useSelector(selectTreasuryFetchParameters);
  useEffect(() => {
    dispatch(fetchTreasuryData(dataFetchParameters));
  }, [dispatch, dataFetchParameters]);

  useEffect(() => {
    dispatch(fetchTreasuryAnnotations());
  }, [dispatch]);

  if (error) {
    return (
      <div>
        <Alert title="Error" status="error">
          {error}
        </Alert>
      </div>
    );
  }

  return (
    <div className="analytic-dashboard-container" style={{ position: 'relative' }}>
      <TabBar />
      <Stack>
        <Box>
          <Grid templateColumns="repeat(24, 1fr)" gap={2}>
            <Text textStyle="2xl">Treasury Details</Text>
            <GridItem colSpan={24}>
              <TreasuryDetailsFilters />
            </GridItem>
            <GridItem colSpan={24}>
              <Card.Root>
                <Card.Body>
                  <Charts />
                </Card.Body>
              </Card.Root>
            </GridItem>
          </Grid>
        </Box>
      </Stack>
      <LoadingOverlay loading={loading} />
    </div>
  );
}
