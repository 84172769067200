import { Box, Center, Flex, Spacer, Tabs } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ResultsGrid from '../../../../components/basic/ResultsGrid';
import Button from '../../../../components/core/Button';
import Card from '../../../../components/core/Card';
import TabListComponent from '../../../../components/core/TabList';
import OrderActionsFormatter from '../../../../components/gridHelpers/OrderActionsFormatter';
import { difference, rowHeight, rowKeyGetter, rowClass } from '../../bulk-updater-views';
import BulkUpdaterModal from './BulkUpdaterModal/BulkUpdaterModal';
import BulkUpdaterSummary from './BulkUpdaterSummary';
import Constants from '../../../../Constants';
import { findOptionMatch } from '../../../../components/gridHelpers/SelectEditor';
import { selectOrderOptions } from '../../../../slices/masterData/selectOrderEntities';
import { Alert } from '../../../../components/ui/alert';

const tabList = [
  { label: 'Edit PO', isDisabled: true, value: 'edit-po' },
  { label: 'Summary', isDisabled: true, value: 'summary' },
  { label: 'Done', isDisabled: true, value: 'done' },
];

const BulkUpdaterEditor = ({ view, selectedResults, onClickExitButton, onSubmitBulkUpdate }) => {
  const [tabIndex, setTabIndex] = useState(tabList[0]?.value);

  const [pos, setPos] = useState([]);
  const [posSelectedIds, setSelectedRows] = useState(new Set());
  const [posChanges, setPosChanges] = useState([]);
  const [posChangesSummary, setPosChangesSummary] = useState([]);
  const [showBulkUpdateModal, setShowBulkUpdateModal] = useState(false);
  const { inputProductOptions, packerPlantOptions, portOptions, shippingLineOptions } = useSelector(selectOrderOptions);

  useEffect(() => {
    if (selectedResults) {
      setPos(JSON.parse(JSON.stringify(selectedResults)));
    }
  }, [selectedResults]);

  useEffect(() => {
    if (pos) {
      const diff = difference(selectedResults, pos);
      setPosChanges(diff);
      const ignoreFields = ['purchase_order_id', 'id'];
      setPosChangesSummary(
        diff.reduce((pos_, po) => {
          const originaPo = selectedResults.find(po_ => po_.id === po.id);
          const addObj = (ori, ob, name) => {
            Object.keys(ob)
              .filter(key => (!ignoreFields.includes(key) && typeof ob[key] !== 'object') || key === 'lot_numbers')
              .forEach(key => {
                const key_name = view.columns.filter(
                  col => col.key === key || col.key === `${name}.${key}` || col.key === `${key.split('_id')[0]}`
                )[0];
                const match = findOptionMatch(packerPlantOptions, ob[key]);
                let old_match = null;
                if (ori) {
                  if (ori[key]) {
                    old_match = findOptionMatch(packerPlantOptions, ori[key])?.name;
                  } else {
                    old_match = '(Empty)';
                  }
                }
                pos_.push({
                  purchaseOrderId: po.id,
                  grinderPurchaseOrderNumber: po.grinder_po_number || originaPo.grinder_po_number,
                  id: '',
                  key: key_name ? key_name.name : null,
                  old: (() => {
                    if (key === 'packer_plant_id') return old_match;
                    if (ori) return ori[key] ? ori[key] : '(Empty)';
                    return null;
                  })(),
                  new: key === 'packer_plant_id' ? match.name : ob[key],
                });
              });
          };
          // order values fields
          addObj(originaPo, po, 'order');
          // order object fields
          Object.keys(po)
            .filter(key => typeof po[key] === 'object' && !Array.isArray(po[key]))
            .forEach(key => addObj(originaPo[key], po[key], key));
          // order array fields
          Object.keys(po)
            .filter(key => Array.isArray(po[key]))
            .forEach(poKey => {
              const items = po[poKey];
              items.forEach(line => {
                Object.keys(line)
                  .filter(key => !ignoreFields.includes(key) && typeof line[key] !== 'object')
                  .forEach(key => {
                    const key_object =
                      poKey === 'lines'
                        ? view.columns.filter(col => col.key === 'lines_aus_logistics' || col.key === 'lines')[0]
                        : view.columns.filter(
                            col =>
                              col.key === poKey || col.key === `${poKey}.${key}` || col.key === `${key.split('_id')[0]}`
                          )[0];
                    const key_object_name = key_object.columns?.find(ln => ln.key === key);
                    const originaLine = originaPo[poKey].find(ln => ln.id === line.id);
                    let port;
                    if (key === 'load_port_id') {
                      port = findOptionMatch(portOptions, originaLine.load_port_id);
                    } else if (key === 'discharge_port_id') {
                      port = findOptionMatch(portOptions, originaLine.discharge_port_id);
                    } else if (key === 'shipping_line_id') {
                      port = findOptionMatch(shippingLineOptions, originaLine.shipping_line_id);
                    } else {
                      port = findOptionMatch(portOptions, originaLine.tranship_port_id);
                    }
                    const new_port = findOptionMatch(portOptions, line[key]);
                    const match = findOptionMatch(inputProductOptions, originaLine.input_product_uid);
                    const product_name = match ? match.name : '';
                    pos_.push({
                      purchaseOrderId: po.id,
                      grinderPurchaseOrderNumber: po.grinder_po_number || originaPo.grinder_po_number,
                      id: poKey === 'lines' ? product_name : '',
                      key: key_object_name
                        ? key_object_name.name
                        : Constants.VIEW_COLS.find(column => column.key === key).name,
                      // key: `${poKey}.${key}`,
                      old: ['load_port_id', 'discharge_port_id', 'tranship_port_id', 'shipping_line_id'].includes(key)
                        ? port
                          ? port.name
                          : '(Empty)'
                        : originaPo[poKey].find(line_ => line_.id === line.id)[key],

                      new:
                        key === 'shipping_line_id'
                          ? findOptionMatch(shippingLineOptions, line[key])?.name
                          : ['load_port_id', 'discharge_port_id', 'tranship_port_id'].includes(key)
                          ? new_port?.name
                          : line[key],
                    });
                  });
              });
            });
          return pos_;
        }, [])
      );
    }
  }, [pos]);

  const moveToEditor = () => setTabIndex(tabList[0]?.value);

  const moveToSummary = () => setTabIndex(tabList[1]?.value);

  const moveToDone = () => setTabIndex(tabList[2]?.value);

  return (
    <>
      <Tabs.Root defaultValue={tabList[0]?.label} value={tabIndex} onValueChange={e => setTabIndex(e.value)}>
        <Flex bg="#EBF0FF" py="20px" px="40px">
          <TabListComponent tabList={tabList} activeTabIndex={tabIndex} />
          <Spacer />
          <Button colorScheme="actionSecondary" width="110px" onClick={onClickExitButton}>
            Exit
          </Button>
        </Flex>
        <Box pb="20px" px="40px" w="100%">
          <Tabs.Content value={tabList[0]?.value}>
            <Card
              {...{
                my: '20px',
                pt: '17px',
                pb: '33px',
                px: '27px',
                borderColor: 'gray.400',
                backgroundColor: 'graySubtle',
                text: 'PO List',
              }}
            >
              <Flex mb="40px">
                <Box w="100px" h="30px" borderWidth="1px" borderRadius="lg">
                  <Button
                    variant="solid"
                    size="sm"
                    height="auto"
                    width="100px"
                    onClick={() => setShowBulkUpdateModal(true)}
                    disabled={isEmpty(posSelectedIds)}
                  >
                    Bulk Edit
                  </Button>
                </Box>
                <Spacer />
                <Alert
                  status="info"
                  w="250px"
                  h="40px"
                  bg="infoBg"
                  color="info"
                  border="1px solid"
                  borderColor="infoBorder"
                  borderRadius="8px"
                  title="You are in Edit Mode"
                  alignItems="center"
                />
                <Spacer />
                <Box
                  {...{
                    w: '175px',
                    borderRadius: '10px',
                    p: '12px',
                    marginRight: '15px',
                  }}
                  shadow="md"
                >
                  <Center
                    {...{
                      fontSize: 'xl',
                      lineHeight: 'xl',
                      color: 'var(--chakra-colors-primary100)',
                      fontWeight: 'bold',
                    }}
                  >
                    {posChanges.length}
                  </Center>
                  <Center fontWeight="400">POs modified</Center>
                </Box>
                <Box
                  {...{
                    w: '175px',
                    borderRadius: '10px',
                    p: '12px',
                  }}
                  shadow="md"
                >
                  <Center
                    {...{
                      fontSize: 'xl',
                      lineHeight: 'xl',
                      color: 'var(--chakra-colors-primary100)',
                      fontWeight: 'bold',
                    }}
                  >
                    {posChangesSummary.length}
                  </Center>
                  <Center fontWeight="400">Fields modified</Center>
                </Box>
              </Flex>
              <ResultsGrid
                id="bulk-updater-editor-grid"
                className="bulk-updater__results-grid"
                columns={view.columns}
                rows={pos}
                rowKeyGetter={rowKeyGetter}
                onGridRowUpdate={setPos}
                selectedRows={posSelectedIds}
                onRowSelect={setSelectedRows}
                rowHeight={rowHeight}
                disableSelect
                rowClass={rowClass}
                height={400}
                minHeight={400}
              />
            </Card>
            <Flex justifyContent="flex-end">
              {/* TODO BULKUPDATER <FieldEditWarningAlert fields={editedFields} /> */}
              <Button ml="auto" width="110px" onClick={moveToSummary} disabled={posChanges.length === 0}>
                Next
              </Button>
            </Flex>
          </Tabs.Content>
          <Tabs.Content value={tabList[1]?.value}>
            <BulkUpdaterSummary
              posChangesSummary={posChangesSummary}
              posChanges={posChanges}
              onClickBackButton={moveToEditor}
              onClickConfirmButton={() => {
                onSubmitBulkUpdate(posChanges);
                moveToDone();
              }}
            />
          </Tabs.Content>
          <Tabs.Content value={tabList[2]?.value}>
            <BulkUpdaterSummary
              posChangesSummary={posChangesSummary}
              posChanges={posChanges}
              done
              onClickFinishButton={() => {
                onClickExitButton();
                moveToEditor();
              }}
            />
          </Tabs.Content>
        </Box>
      </Tabs.Root>
      <OrderActionsFormatter.Modals />
      {showBulkUpdateModal && (
        <BulkUpdaterModal
          {...{
            isOpen: showBulkUpdateModal,
            view,
            pos,
            posSelectedIds,
            onCloseModal: () => setShowBulkUpdateModal(false),
            onSubmit: pos_ => setPos(pos_),
          }}
        />
      )}
    </>
  );
};

BulkUpdaterEditor.propTypes = {
  view: PropTypes.objectOf(Object).isRequired,
  selectedResults: PropTypes.arrayOf(PropTypes.objectOf(Object)).isRequired,
  onClickExitButton: PropTypes.func.isRequired,
  onSubmitBulkUpdate: PropTypes.func.isRequired,
};

export default BulkUpdaterEditor;
