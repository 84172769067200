import React, { useEffect, useCallback } from 'react';
import { Text, Card } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router';

import { updateActiveVolumeGrouping, updateVolumeOptions } from '../../../slices/markets/marketReducers';
import {
  selectActiveVolumeGrouping,
  selectVolumeGroups,
  selectActiveMarket,
  selectVolumeOptions,
} from '../../../slices/markets/selectors/selectBasicMarketData';
import { selectMarketsVolumeData } from '../../../slices/markets/selectors/selectMarketsVolumeData';
import VolumeTable from '../../../components/analyticDashboard/markets/VolumeTable/VolumeTable';
import { ChevronRight } from '../../../shared/Icons';
import IUnleashClassFlagProvider from '../../../components/unleash/UnleashClassFlagProvider';
import Constants from '../../../Constants';

// This container specifically serves the cost + performance card.
function CostPerformance() {
  const dispatch = useDispatch();
  const { market: marketParam } = useParams();
  const { currentData, previousData } = useSelector(selectMarketsVolumeData);
  const volumeGroups = useSelector(selectVolumeGroups);
  const activeVolumeGrouping = useSelector(selectActiveVolumeGrouping);
  const activeMarket = useSelector(selectActiveMarket);
  const volumeOptions = useSelector(selectVolumeOptions);

  const handleActiveVolumeGroupingChange = useCallback(
    grouping => {
      dispatch(updateActiveVolumeGrouping(grouping.key));
    },
    [dispatch]
  );

  const handleVolumeOptionsChange = useCallback(
    newOptions => {
      dispatch(updateVolumeOptions(newOptions));
    },
    [dispatch]
  );

  // Make sure selected grouping is available
  useEffect(() => {
    if (!volumeGroups.includes(activeVolumeGrouping)) {
      dispatch(updateActiveVolumeGrouping(volumeGroups[0].key));
    }
  }, [activeVolumeGrouping, volumeGroups]);

  return (
    <Card.Root>
      <Card.Body>
        <Link to={`/analytic-dashboard/market/${marketParam}/volume-details`}>
          <Text textStyle="2xl" style={{ fontWeight: 'normal' }}>
            Volume
            <div style={{ display: 'inline-block', width: '18px' }}>
              <ChevronRight />
            </div>
          </Text>
        </Link>
        <VolumeTable
          activeMarket={activeMarket}
          marketsVolumeData={currentData}
          previousMarketsVolumeData={previousData}
          volumeGroups={volumeGroups}
          activeVolumeGrouping={activeVolumeGrouping}
          onActiveVolumeGroupingChange={handleActiveVolumeGroupingChange}
          onVolumeOptionsChange={handleVolumeOptionsChange}
          volumeOptions={volumeOptions}
        />
      </Card.Body>
    </Card.Root>
  );
}

const CostPerformanceUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.MARKET_DASHBOARD_COST_PERFORMANCE}
        show={<CostPerformance {...props} />}
        hide=""
      />
    </>
  );
};

export default CostPerformanceUnleashController;
