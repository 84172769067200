import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FilterSelectGeneric from '../../../../components/analyticDashboard/qualityDetails/shared/FilterSelectGeneric/FilterSelectGeneric';
import {
  selectQualityClaimsFilterBy,
  selectQualityClaimsStatus,
  selectQualityClaimsCount,
  selectQualityClaimsTimeRange,
  selectQualityClaimsAmount,
  selectQualityClaimsGroupBy,
  selectQualityClaimsFilteredByPackerPlant,
  selectQualityClaimsGroupedByPackerPlant,
} from '../../../../slices/quality-details-claims/selectors/selectBasicDetailsClaimsData';
import selectQualityClaimsBottomTenPackerPlants from '../../../../slices/quality-details-claims/selectors/selectQualityClaimsBottomTenPackerPlants';
import {
  setQualityClaimsGroupBy,
  setQualityClaimsFilterBy,
  setQualityClaimsGroupedByPackerPlant,
  setQualityClaimsFilteredByPackerPlant,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsReducers';
import {
  TIME_RANGE_OPTIONS,
  GROUP_BY_OPTIONS,
  FILTER_BY_OPTIONS,
  STATUS_OPTIONS,
  COUNT_OPTIONS,
} from '../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';
import styles from './QualityDetailsClaims.module.css';
import FilterCheckboxGroupGeneric from '../../../../components/analyticDashboard/qualityDetails/shared/FilterCheckboxGroupGeneric/FilterCheckboxGroupGeneric';
import FilterRadioGeneric from '../../../../components/analyticDashboard/qualityDetails/shared/FilterRadioGeneric/FilterRadioGeneric';
import FilterMultiSelect from '../../../../components/analyticDashboard/qualityDetails/shared/FilterMultiSelect/FilterMultiSelect';
import { PropTypes } from 'prop-types';

const DEFAULT_NUM_OF_YEARS_SELECTED = 3;
export const MAX_NUM_OF_ITEMS_CHECKED = 10;

function getColors(selectedFields, availableFields, isMarkets) {
  const colors = [
    '#09AFA3', // teal
    '#FF1577', // magenta
    '#5039B5', // purple
    '#FED000', // yellow
    '#00B5F2', // blue
    '#FC5D36', // orange
    '#4D536A', // dark grey
    '#AB2424', // maroon
    '#FEC5B8', // peach
    '#DCC48E', // golden
  ];
  const colorsObject = {};

  if (isMarkets) {
    const colorsForMarkets = [
      '#AB2424', // maroon
      '#FEC5B8', // peach
    ];
    availableFields.forEach((x, i) => {
      colorsObject[x] = colorsForMarkets[i % colorsForMarkets.length];
    });
    return colorsObject;
  }

  if (availableFields && availableFields.length <= MAX_NUM_OF_ITEMS_CHECKED) {
    availableFields.forEach((x, i) => {
      colorsObject[x] = colors[i % colors.length];
    });
  } else {
    selectedFields.forEach((x, i) => {
      colorsObject[x] = colors[i % colors.length];
    });
  }

  return colorsObject;
}

function addBottomTenToTheListOfPackerPlants(packerPlants, bottomTenPackerPlants) {
  const bottomTen = {
    id: 'bottom_10',
    name: 'Bottom 10',
    includesPackerPlants: bottomTenPackerPlants,
    bottomTenPackerPlants,
  };
  return [bottomTen].concat(packerPlants);
}

function removeNumberOfPackerPlantsFromName(name) {
  const ind = name.indexOf('(');
  if (ind === -1) return name;
  return name.slice(0, ind);
}

function Paragraph(props) {
  const { options } = props;
  return <div style={{ marginTop: 20, marginBottom: 10 }}>{options[0]}</div>;
}

Paragraph.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
};

const CONTROL_TYPE = {
  CHECKBOX_GROUP: FilterCheckboxGroupGeneric,
  RADIO_GROUP: FilterRadioGeneric,
  SELECT: FilterSelectGeneric,
  MULTI_SELECT: FilterMultiSelect,
  PARAGRAPH: Paragraph,
};

export default function QualityDetailsClaimsSidebar(props) {
  const {
    dataColors,
    setDataColors,
    availableYears,
    selectedYears,
    setSelectedYears,
    selectedStatuses,
    setSelectedStatuses,
    availableCategories,
    selectedCategories,
    setSelectedCategories,
    availableClaimAmounts,
    selectedClaimAmounts,
    setSelectedClaimAmounts,
    availableDaysToResolve,
    selectedDaysToResolve,
    setSelectedDaysToResolve,
    availablePackerPlants,
    highlightedPackerPlants,
    setHighlightedPackerPlants,
  } = props;
  const statusMode = useSelector(selectQualityClaimsStatus);
  const countMode = useSelector(selectQualityClaimsCount);
  const amountMode = useSelector(selectQualityClaimsAmount);
  const groupByMode = useSelector(selectQualityClaimsGroupBy);
  const filterByMode = useSelector(selectQualityClaimsFilterBy);
  const filteredByPackerPlant = useSelector(selectQualityClaimsFilteredByPackerPlant);
  const groupedByPackerPlant = useSelector(selectQualityClaimsGroupedByPackerPlant);
  const timeRange = useSelector(selectQualityClaimsTimeRange);
  const bottomTenPackerPlants = useSelector(selectQualityClaimsBottomTenPackerPlants);

  const dispatch = useDispatch();

  const textForGroupBy = useMemo(() => {
    switch (statusMode) {
      case STATUS_OPTIONS.SUBMITTED_VS_FINALISED:
        return 'ratio of finalised to submitted claims';
      case STATUS_OPTIONS.SUBMITTED_VS_REJECTED:
        return 'ratio of rejected to submitted claims';
      case STATUS_OPTIONS.FINALISED_VS_REJECTED:
        return 'ratio of rejected to finalised claims';
      default:
        return '';
    }
  }, [statusMode]);

  const extraComponentsTypes = {
    paragraph: text => ({
      label: '',
      control: CONTROL_TYPE.PARAGRAPH,
      options: [text],
    }),
  };

  const componentsTypes = {
    years: () => ({
      label: '',
      control: CONTROL_TYPE.CHECKBOX_GROUP,
      options: availableYears,
      value: selectedYears,
      setValue: setSelectedYears,
    }),
    status: options => ({
      label: '',
      control: CONTROL_TYPE.CHECKBOX_GROUP,
      options,
      value: selectedStatuses,
      setValue: setSelectedStatuses,
      disabled: groupByMode === GROUP_BY_OPTIONS.PACKER_PLANT,
    }),
    groupBy: allowOnlyPackerPlant => ({
      label: 'Group by',
      control: CONTROL_TYPE.SELECT,
      options: allowOnlyPackerPlant
        ? [GROUP_BY_OPTIONS.PACKER_PLANT].filter(x => x !== filterByMode)
        : [
            GROUP_BY_OPTIONS.CATEGORY,
            GROUP_BY_OPTIONS.CLAIM_AMOUNT,
            GROUP_BY_OPTIONS.DAYS_TO_RESOLVE,
            (!countMode || !amountMode) && GROUP_BY_OPTIONS.PACKER_PLANT,
          ].filter(x => x !== filterByMode && !!x),
      value: groupByMode,
      setValue: x => {
        dispatch(setQualityClaimsGroupBy(x));
      },
      includeNullOption: true,
      style: groupByMode === GROUP_BY_OPTIONS.PACKER_PLANT ? { marginBottom: 0 } : undefined,
    }),
    filterBy: () => ({
      label: 'Filter by',
      control: CONTROL_TYPE.SELECT,
      options: [
        FILTER_BY_OPTIONS.CATEGORY,
        FILTER_BY_OPTIONS.CLAIM_AMOUNT,
        FILTER_BY_OPTIONS.DAYS_TO_RESOLVE,
        FILTER_BY_OPTIONS.PACKER_PLANT,
      ].filter(x => x !== groupByMode),
      value: filterByMode,
      setValue: x => {
        dispatch(setQualityClaimsFilterBy(x));
      },
      includeNullOption: true,
      style: filterByMode ? { marginBottom: 0 } : undefined,
    }),
    categoryCheckbox: () => ({
      label: '',
      control: CONTROL_TYPE.CHECKBOX_GROUP,
      options: availableCategories,
      value: selectedCategories,
      setValue: x => setSelectedCategories(x.sort()),
    }),
    categorySelect: () => ({
      label: '',
      control: CONTROL_TYPE.SELECT,
      options: availableCategories,
      value: selectedCategories[0],
      setValue: x => setSelectedCategories([x]),
    }),
    claimAmountCheckbox: () => ({
      label: '',
      control: CONTROL_TYPE.CHECKBOX_GROUP,
      options: availableClaimAmounts,
      value: selectedClaimAmounts,
      setValue: x => setSelectedClaimAmounts(x),
    }),
    claimAmountSelect: () => ({
      label: '',
      control: CONTROL_TYPE.SELECT,
      options: availableClaimAmounts,
      value: selectedClaimAmounts[0],
      setValue: x => setSelectedClaimAmounts([x]),
    }),
    daysToResolveCheckbox: () => ({
      label: '',
      control: CONTROL_TYPE.CHECKBOX_GROUP,
      options: availableDaysToResolve,
      value: selectedDaysToResolve,
      setValue: x => setSelectedDaysToResolve(x),
    }),
    daysToResolveSelect: () => ({
      label: '',
      control: CONTROL_TYPE.SELECT,
      options: availableDaysToResolve,
      value: selectedDaysToResolve[0],
      setValue: x => setSelectedDaysToResolve([x]),
    }),
    filterByPackerPlantSelect: () => ({
      label: '',
      control: CONTROL_TYPE.SELECT,
      options: [
        ...addBottomTenToTheListOfPackerPlants(availablePackerPlants || [], bottomTenPackerPlants).map(x => x.name),
      ],
      value: filteredByPackerPlant?.name,
      setValue: x =>
        dispatch(
          setQualityClaimsFilteredByPackerPlant(
            addBottomTenToTheListOfPackerPlants(availablePackerPlants || [], bottomTenPackerPlants).find(
              p => p.name === x
            )
          )
        ),
    }),
    groupByPackerPlantSelect: () => ({
      label: '',
      control: CONTROL_TYPE.SELECT,
      options: [
        ...addBottomTenToTheListOfPackerPlants(availablePackerPlants || [], bottomTenPackerPlants).map(x => x.name),
      ],
      value: groupedByPackerPlant?.name,
      setValue: x => {
        const packerPlant = addBottomTenToTheListOfPackerPlants(
          availablePackerPlants || [],
          bottomTenPackerPlants
        ).find(p => p.name === x);
        dispatch(setQualityClaimsGroupedByPackerPlant(packerPlant));
        if (!packerPlant.bottomTenPackerPlants) {
          setHighlightedPackerPlants([packerPlant.name]);
        }
      },
      style: { marginBottom: 0 },
      extraControl: extraComponentsTypes.paragraph(
        groupedByPackerPlant?.includesPackerPlants
          ? groupedByPackerPlant.includesPackerPlants.length === 10
            ? `Establishments with the highest ${
                selectedStatuses?.length > 1
                  ? textForGroupBy
                  : countMode === COUNT_OPTIONS.TOTAL
                  ? 'claim count'
                  : 'claim amount'
              } for the selected time range.`
            : groupedByPackerPlant.includesPackerPlants.length > 10
            ? `10 ${removeNumberOfPackerPlantsFromName(groupedByPackerPlant.name)} members with the highest ${
                selectedStatuses?.length > 1
                  ? textForGroupBy
                  : countMode === COUNT_OPTIONS.TOTAL
                  ? 'claim count'
                  : 'claim amount'
              } for the selected time range.`
            : `All ${removeNumberOfPackerPlantsFromName(groupedByPackerPlant.name)} members`
          : ''
      ),
    }),
    packerPlantRadio: options => ({
      label: '',
      control: CONTROL_TYPE.RADIO_GROUP,
      options,
      value: highlightedPackerPlants.length === options.length ? undefined : highlightedPackerPlants[0],
      setValue: setHighlightedPackerPlants,
      resetOnClick: true,
      withColors: true,
    }),
  };

  // reset highlighterPackerPlants when they are not needed any more
  useEffect(() => {
    if (groupByMode !== GROUP_BY_OPTIONS.PACKER_PLANT) {
      setHighlightedPackerPlants([]);
    }
  }, [groupByMode, groupedByPackerPlant]);

  // resetting filters when they are not needed any more and setting only the relevant ones
  useEffect(() => {
    if (timeRange === TIME_RANGE_OPTIONS.YOY && availableYears) {
      setSelectedYears(availableYears.slice(0, DEFAULT_NUM_OF_YEARS_SELECTED));
      setSelectedStatuses([statusMode]);
    } else if (timeRange === TIME_RANGE_OPTIONS.HISTORICAL) {
      setSelectedYears([]);
      if (statusMode === STATUS_OPTIONS.SUBMITTED_VS_FINALISED) {
        setSelectedStatuses([STATUS_OPTIONS.SUBMITTED, STATUS_OPTIONS.FINALISED]);
      } else if (statusMode === STATUS_OPTIONS.FINALISED_VS_REJECTED) {
        setSelectedStatuses([STATUS_OPTIONS.FINALISED, STATUS_OPTIONS.REJECTED]);
      } else if (statusMode === STATUS_OPTIONS.SUBMITTED_VS_REJECTED) {
        setSelectedStatuses([STATUS_OPTIONS.SUBMITTED, STATUS_OPTIONS.REJECTED]);
      } else {
        setSelectedStatuses([statusMode]);
      }
    }
  }, [timeRange, statusMode, availableYears]);

  // resetting filters when they are not needed any more and setting only the relevant ones
  useEffect(() => {
    if (groupByMode === GROUP_BY_OPTIONS.CATEGORY) {
      setSelectedCategories(availableCategories.slice(0, MAX_NUM_OF_ITEMS_CHECKED));
      if (filterByMode !== FILTER_BY_OPTIONS.CLAIM_AMOUNT) setSelectedClaimAmounts([]);
      if (filterByMode !== FILTER_BY_OPTIONS.DAYS_TO_RESOLVE) setSelectedDaysToResolve([]);
      dispatch(setQualityClaimsGroupedByPackerPlant(undefined));
    } else if (groupByMode === GROUP_BY_OPTIONS.CLAIM_AMOUNT) {
      setSelectedClaimAmounts(availableClaimAmounts.slice(0, MAX_NUM_OF_ITEMS_CHECKED));
      if (filterByMode !== FILTER_BY_OPTIONS.CATEGORY) setSelectedCategories([]);
      if (filterByMode !== FILTER_BY_OPTIONS.DAYS_TO_RESOLVE) setSelectedDaysToResolve([]);
      dispatch(setQualityClaimsGroupedByPackerPlant(undefined));
    } else if (groupByMode === GROUP_BY_OPTIONS.DAYS_TO_RESOLVE) {
      setSelectedDaysToResolve(availableDaysToResolve.slice(0, MAX_NUM_OF_ITEMS_CHECKED));
      if (filterByMode !== FILTER_BY_OPTIONS.CLAIM_AMOUNT) setSelectedClaimAmounts([]);
      if (filterByMode !== FILTER_BY_OPTIONS.CATEGORY) setSelectedCategories([]);
      dispatch(setQualityClaimsGroupedByPackerPlant(undefined));
    } else {
      if (groupByMode === GROUP_BY_OPTIONS.PACKER_PLANT) {
        const packerPlant = addBottomTenToTheListOfPackerPlants(
          availablePackerPlants || [],
          bottomTenPackerPlants
        ).find(p => p.id === 'bottom_10');
        dispatch(setQualityClaimsGroupedByPackerPlant(packerPlant));
        setHighlightedPackerPlants(packerPlant.bottomTenPackerPlants.map(x => x.name));
      } else {
        dispatch(setQualityClaimsGroupedByPackerPlant(undefined));
      }
      if (filterByMode !== FILTER_BY_OPTIONS.CATEGORY) setSelectedCategories([]);
      if (filterByMode !== FILTER_BY_OPTIONS.CLAIM_AMOUNT) setSelectedClaimAmounts([]);
      if (filterByMode !== FILTER_BY_OPTIONS.DAYS_TO_RESOLVE) setSelectedDaysToResolve([]);
    }
  }, [groupByMode, availablePackerPlants]);

  // resetting filters when they are not needed any more and setting only the relevant ones
  useEffect(() => {
    if (filterByMode === FILTER_BY_OPTIONS.CATEGORY) {
      setSelectedCategories([availableCategories[0]]);
      if (groupByMode !== GROUP_BY_OPTIONS.CLAIM_AMOUNT) setSelectedClaimAmounts([]);
      if (groupByMode !== GROUP_BY_OPTIONS.DAYS_TO_RESOLVE) setSelectedDaysToResolve([]);
      dispatch(setQualityClaimsFilteredByPackerPlant(undefined));
    } else if (filterByMode === FILTER_BY_OPTIONS.CLAIM_AMOUNT) {
      setSelectedClaimAmounts([availableClaimAmounts[0]]);
      if (groupByMode !== GROUP_BY_OPTIONS.CATEGORY) setSelectedCategories([]);
      if (groupByMode !== GROUP_BY_OPTIONS.DAYS_TO_RESOLVE) setSelectedDaysToResolve([]);
      dispatch(setQualityClaimsFilteredByPackerPlant(undefined));
    } else if (filterByMode === FILTER_BY_OPTIONS.DAYS_TO_RESOLVE) {
      setSelectedDaysToResolve([availableDaysToResolve[0]]);
      if (groupByMode !== GROUP_BY_OPTIONS.CLAIM_AMOUNT) setSelectedClaimAmounts([]);
      if (groupByMode !== GROUP_BY_OPTIONS.CATEGORY) setSelectedCategories([]);
      dispatch(setQualityClaimsFilteredByPackerPlant(undefined));
    } else {
      if (filterByMode === FILTER_BY_OPTIONS.PACKER_PLANT) {
        if (filteredByPackerPlant === undefined) {
          dispatch(
            setQualityClaimsFilteredByPackerPlant(
              addBottomTenToTheListOfPackerPlants(availablePackerPlants || [], bottomTenPackerPlants).find(
                p => p.id === 'bottom_10'
              )
            )
          );
        }
      } else {
        dispatch(setQualityClaimsFilteredByPackerPlant(undefined));
      }
      if (groupByMode !== GROUP_BY_OPTIONS.CATEGORY) setSelectedCategories([]);
      if (groupByMode !== GROUP_BY_OPTIONS.CLAIM_AMOUNT) setSelectedClaimAmounts([]);
      if (groupByMode !== GROUP_BY_OPTIONS.DAYS_TO_RESOLVE) setSelectedDaysToResolve([]);
    }
  }, [filterByMode]);

  // colors setting
  useEffect(() => {
    if (timeRange === TIME_RANGE_OPTIONS.YOY) {
      if (availableYears && selectedYears) {
        setDataColors(getColors(selectedYears, availableYears));
      }
    } else if (timeRange === TIME_RANGE_OPTIONS.HISTORICAL) {
      const extraColors =
        groupedByPackerPlant && groupedByPackerPlant.bottomTenPackerPlants
          ? getColors(
              groupedByPackerPlant.bottomTenPackerPlants.map(x => x.name),
              groupedByPackerPlant.bottomTenPackerPlants.map(x => x.name)
            )
          : undefined;
      if (statusMode === STATUS_OPTIONS.SUBMITTED_VS_FINALISED) {
        setDataColors({
          [STATUS_OPTIONS.SUBMITTED]: '#4E5984',
          [STATUS_OPTIONS.FINALISED]: '#00B5F2',
          ...extraColors,
        });
      } else if (statusMode === STATUS_OPTIONS.SUBMITTED_VS_REJECTED) {
        setDataColors({
          [STATUS_OPTIONS.SUBMITTED]: '#4E5984',
          [STATUS_OPTIONS.REJECTED]: '#F8617B',
          ...extraColors,
        });
      } else if (statusMode === STATUS_OPTIONS.FINALISED_VS_REJECTED) {
        setDataColors({
          [STATUS_OPTIONS.FINALISED]: '#00B5F2',
          [STATUS_OPTIONS.REJECTED]: '#F8617B',
          ...extraColors,
        });
      } else if (groupByMode === GROUP_BY_OPTIONS.CATEGORY) {
        setDataColors(getColors(selectedCategories, availableCategories));
      } else if (groupByMode === GROUP_BY_OPTIONS.CLAIM_AMOUNT) {
        const colors = {};
        const claimAmountColors = [
          '#3DE5C7', // green
          '#D0F1EE', // light green
          '#E3E8F9', // grey
          '#FEC5B8', // light red
          '#F8617B', // red
        ];
        availableClaimAmounts.forEach((x, i) => {
          colors[x] = claimAmountColors[i % claimAmountColors.length];
        });
        setDataColors(colors);
      } else if (groupByMode === GROUP_BY_OPTIONS.DAYS_TO_RESOLVE) {
        const colors = {};
        const daysToResolveColors = [
          '#3DE5C7', // green
          '#E3E8F9', // grey
          '#F8617B', // red
        ];
        availableDaysToResolve.forEach((x, i) => {
          colors[x] = daysToResolveColors[i % daysToResolveColors.length];
        });
        setDataColors(colors);
      } else if (
        groupByMode === GROUP_BY_OPTIONS.PACKER_PLANT &&
        groupedByPackerPlant &&
        groupedByPackerPlant.bottomTenPackerPlants
      ) {
        setDataColors(
          getColors(
            groupedByPackerPlant.bottomTenPackerPlants.map(x => x.name),
            groupedByPackerPlant.bottomTenPackerPlants.map(x => x.name)
          )
        );
      } else if (
        groupByMode === GROUP_BY_OPTIONS.PACKER_PLANT &&
        groupedByPackerPlant &&
        !groupedByPackerPlant.bottomTenPackerPlants
      ) {
        setDataColors({ [groupedByPackerPlant.name]: '#4E5984' });
      }
    }
  }, [timeRange, statusMode, selectedYears, selectedStatuses, groupByMode, groupedByPackerPlant]);

  // components config
  const components = useMemo(() => {
    if (!countMode && !amountMode) {
      return [];
    }

    let filterByAdditionalControl;
    if (filterByMode === FILTER_BY_OPTIONS.CATEGORY) {
      filterByAdditionalControl = componentsTypes.categorySelect();
    } else if (filterByMode === FILTER_BY_OPTIONS.CLAIM_AMOUNT) {
      filterByAdditionalControl = componentsTypes.claimAmountSelect();
    } else if (filterByMode === FILTER_BY_OPTIONS.DAYS_TO_RESOLVE) {
      filterByAdditionalControl = componentsTypes.daysToResolveSelect();
    } else if (filterByMode === FILTER_BY_OPTIONS.PACKER_PLANT) {
      filterByAdditionalControl = componentsTypes.filterByPackerPlantSelect();
    }

    let groupByAdditionalControl;
    let packerPlantRadio;
    if (groupByMode === GROUP_BY_OPTIONS.CATEGORY) {
      groupByAdditionalControl = componentsTypes.categoryCheckbox();
    } else if (groupByMode === GROUP_BY_OPTIONS.CLAIM_AMOUNT) {
      groupByAdditionalControl = componentsTypes.claimAmountCheckbox();
    } else if (groupByMode === GROUP_BY_OPTIONS.DAYS_TO_RESOLVE) {
      groupByAdditionalControl = componentsTypes.daysToResolveCheckbox();
    } else if (groupByMode === GROUP_BY_OPTIONS.PACKER_PLANT) {
      groupByAdditionalControl = componentsTypes.groupByPackerPlantSelect();
      if (groupedByPackerPlant?.bottomTenPackerPlants) {
        packerPlantRadio = componentsTypes.packerPlantRadio(
          groupedByPackerPlant?.bottomTenPackerPlants.map(x => x.name)
        );
      }
    }

    if (timeRange === TIME_RANGE_OPTIONS.YOY) {
      return [componentsTypes.years(), componentsTypes.filterBy(), filterByAdditionalControl];
    }
    if (timeRange === TIME_RANGE_OPTIONS.HISTORICAL) {
      if (statusMode === STATUS_OPTIONS.SUBMITTED_VS_FINALISED) {
        return [
          componentsTypes.status([STATUS_OPTIONS.SUBMITTED, STATUS_OPTIONS.FINALISED]),
          !countMode || !amountMode ? componentsTypes.groupBy(true) : undefined,
          groupByAdditionalControl,
          packerPlantRadio,
          componentsTypes.filterBy(),
          filterByAdditionalControl,
        ];
      }
      if (statusMode === STATUS_OPTIONS.SUBMITTED_VS_REJECTED) {
        return [
          componentsTypes.status([STATUS_OPTIONS.SUBMITTED, STATUS_OPTIONS.REJECTED]),
          !countMode || !amountMode ? componentsTypes.groupBy(true) : undefined,
          groupByAdditionalControl,
          packerPlantRadio,
          componentsTypes.filterBy(),
          filterByAdditionalControl,
        ];
      }
      if (statusMode === STATUS_OPTIONS.FINALISED_VS_REJECTED) {
        return [
          componentsTypes.status([STATUS_OPTIONS.FINALISED, STATUS_OPTIONS.REJECTED]),
          !countMode || !amountMode ? componentsTypes.groupBy(true) : undefined,
          groupByAdditionalControl,
          packerPlantRadio,
          componentsTypes.filterBy(),
          filterByAdditionalControl,
        ];
      }
      return [
        componentsTypes.groupBy(),
        groupByAdditionalControl,
        packerPlantRadio,
        componentsTypes.filterBy(),
        filterByAdditionalControl,
      ];
    }
    return [];
  }, [
    countMode,
    amountMode,
    timeRange,
    statusMode,
    availableYears,
    selectedYears,
    selectedStatuses,
    groupByMode,
    filterByMode,
    selectedCategories,
    selectedClaimAmounts,
    selectedDaysToResolve,
    filteredByPackerPlant,
    groupedByPackerPlant,
    highlightedPackerPlants,
  ]);

  const component = config => {
    if (!config.options) return null;
    return (
      <config.control
        label={config.label}
        options={config.options}
        value={config.value}
        onChange={config.setValue}
        maxNumOfValues={config.maxNumOfValues}
        resetOnClick={config.resetOnClick}
        withColors={config.withColors}
        dataColors={dataColors}
        indicator={config.indicator}
        disabled={config.isDisabled}
        includeNullOption={config.includeNullOption}
      />
    );
  };

  return (
    <div>
      {components
        .filter(x => !!x)
        .map((x, i) => {
          return (
            <div key={`component-${i}`} className={styles.QualityDetailsClaims_sideBarFilterContainer} style={x.style}>
              {component(x)}
              {x.extraControl && component(x.extraControl)}
            </div>
          );
        })}
    </div>
  );
}

QualityDetailsClaimsSidebar.propTypes = {
  dataColors: PropTypes.shape({}),
  setDataColors: PropTypes.func,
  availableYears: PropTypes.arrayOf(PropTypes.number),
  selectedYears: PropTypes.arrayOf(PropTypes.number),
  setSelectedYears: PropTypes.func,
  selectedStatuses: PropTypes.arrayOf(PropTypes.string),
  setSelectedStatuses: PropTypes.func,
  availableCategories: PropTypes.arrayOf(PropTypes.string),
  selectedCategories: PropTypes.arrayOf(PropTypes.string),
  setSelectedCategories: PropTypes.func,
  availableClaimAmounts: PropTypes.arrayOf(PropTypes.number),
  selectedClaimAmounts: PropTypes.arrayOf(PropTypes.number),
  setSelectedClaimAmounts: PropTypes.func,
  availableDaysToResolve: PropTypes.arrayOf(PropTypes.number),
  selectedDaysToResolve: PropTypes.arrayOf(PropTypes.number),
  setSelectedDaysToResolve: PropTypes.func,
  availablePackerPlants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      includesPackerPlants: PropTypes.arrayOf(PropTypes.string),
      bottomTenPackerPlants: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  highlightedPackerPlants: PropTypes.arrayOf(PropTypes.string),
  setHighlightedPackerPlants: PropTypes.func,
};
